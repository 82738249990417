const initialState = {} ;

function forHome(state = initialState, action){
    switch(action.type){
        
        case 'VIEW_PACKAGE' : 
            state = action.data;
            return state;
           

        default : return state;
    }

}

export default forHome;