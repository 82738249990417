import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import url from '../url';
import { ADD_TO_CART, ADD_QUANTITY, REMOVE_QUANTITY } from '../store/actions/cart';
import { VIEW_PACKAGE } from '../store/actions/ViewPacakage';
import PackageDetails from './PackageDetails';

function Package(){

    const [packag, setPackage] = useState([{unit:{}, photos:[]}]);
    const { id } = useParams();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const [clss, setClss] = useState({display:"none"});


    useEffect(()=>{
        fetch(`${url}/package/search?page=${0}&limit=${0}&search=${id}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setPackage(data.result.data);
            }
        });
        },[id]);



        useEffect(()=>{
            window.scrollTo(0,0)
        
          },[]);


    return(
        <div className=' w-full -mt-20 h-auto flex flex-wrap gap-4 p-10 mb-10'>
            <h1 className=' absolute top-16 pt-4 text-3xl'>Packages</h1>
            {
                packag.map((data, index)=>{

                    const d = cart.filter((cart)=>{
                        return(cart.id === data._id)
                    });


                    return(
                        <div key={index} className=" w-[260px] bg-slate-200 p-2 rounded-md">
                            <div>
                                {
                                    (data.photos.length > 0)?
                                    <img className=' w-[260px] h-[260px] ' src={data.photos[0].img} alt="" />:<></>
                                }
                            </div>
                            <div>
                                <h1>{data.name}</h1>
                                <h3>
                                    <span>Price : </span> 
                                    {
                                        (data.discountPrice > 0)? <span className=' text-red-600 line-through'>{data.price}  ৳</span> :<></>
                                    }
                                    {
                                        (data.discountPrice > 0)? <span> {data.discountPrice}  ৳</span> :<span>{data.price}  ৳</span>
                                    }
                                </h3>
                                <h3><span>Quantity: </span><span>{data.quantity}</span> <span>{data.unit.shortName}</span></h3>
                                <div className=' flex gap-2 pt-4'>
                                    {
                                        (d[0])?
                                        <div className=' w-28 border-2 border-orange-600 rounded-md flex justify-between'>
                                            <button onClick={()=> dispatch(REMOVE_QUANTITY({id:data._id}))} className=' bg-orange-600 text-white px-[5px]'><i className="fa-solid fa-minus"></i></button>
                                            <span className=' text-orange-600'>{d[0].quantity}</span>
                                            <button onClick={()=> dispatch(ADD_QUANTITY({id:data._id}))} className=' bg-orange-600 text-white px-[5px]'><i className="fa-solid fa-plus"></i></button>
                                        </div>
                                        :
                                            (data.discountPrice > 0)?
                                            <button onClick={()=>dispatch(ADD_TO_CART({"id":data._id, "name":data.name, "pPrice":data.purchasePrice, "price":data.price, "discountPrice":data.discountPrice,"unit":data.unit.shortName,"pdQuantity":data.quantity, "quantity":1 }))} className=' px-2 py-[2px] bg-orange-600 text-white rounded-md'>Add to cart</button>
                                            :
                                            <button onClick={()=>dispatch(ADD_TO_CART({"id":data._id, "name":data.name, "pPrice":data.purchasePrice, "price":data.price, "discountPrice":data.price,"unit":data.unit.shortName,"pdQuantity":data.quantity, "quantity":1 }))} className=' px-2 py-[2px] bg-orange-600 text-white rounded-md'>Add to cart</button>
                                    }
                                                                
                                    {/* <button onClick={()=> {dispatch(VIEW_PACKAGE(data)); setClss({display:"block"});  } } className=' px-2 py-[2px] bg-orange-600 text-white rounded-md'>View Package</button> */}
                                    <Link className=' px-2 py-[2px] bg-orange-600 text-white rounded-md' onClick={()=> {dispatch(VIEW_PACKAGE(data)); setClss({display:"block"});  } } to={`/package/details/${data._id}`}>View Package</Link>
                                </div>
                            </div>
     
                        </div>
                    )
                })
            }

            {/* <PackageDetails clss={clss} setClss={setClss}  /> */}
        </div>
    );
}

export default Package;