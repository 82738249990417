import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import url from '../url';
import { Alert1, Alert2, AlertContainer } from '../components/Alert';

function RequestService(){

    const [value, setValue] =  useState({name:'', email:'', phone:'', address:'', description:''});
    const [alert, setAlert] = useState([]);
    const navigate = useNavigate();



    function input(e){
        setValue({...value, [e.target.name]:e.target.value});
    }



    function send(){
        fetch(`${url}/customer/requestService`,{
            method:"POST",
            body:  JSON.stringify(value),
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setTimeout(()=>{
                    navigate(-1);
                },8000);
                setAlert((alert)=>[...alert, <Alert1 key={ Date.now()} title="Successful!" message={data.message} />]);
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
            }
          });

    }
    

    useEffect(()=>{
        window.scrollTo(0,0)
    
      },[]);
      
    

    return(
        <div className=' p-4 -mt-10 mb-20'>
            <div className= " absolute top-16 right-2 ">
                <AlertContainer>
                    {alert}
                </AlertContainer>
            </div>
            <form className='  md:w-[600px] h-auto mx-auto p-4 pb-10 flex flex-col rounded-md shadow-md bg-slate-300 relative ' >
                <h1 className=' text-center text-xl'> Customize Service</h1>
                <label htmlFor='name' >Name:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="text" name="name" id="name" value={value.name} placeholder='Enter your name' /> 
                <label htmlFor='email' >Email:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="email" name="email" id="email" value={value.email} placeholder='Enter your email' /> 
                <label htmlFor='phone' >phone:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="text" name="phone" id="phone" value={value.phone} placeholder='Enter your phone number' /> 
                <label htmlFor='address' >Address:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="text" name="address" id="address" value={value.address} placeholder='Enter your address number' /> 
                <label htmlFor='description' >Details:</label>
                <textarea onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="description" name="description" id="description" value={value.description} placeholder='Enter your requirement...' rows={5} /> 
                <button onClick={send} className=' bg-orange-600 w-20 text-white mt-4 rounded-sm' type='button'>Send</button>
            </form>
        </div>
    )
}

export default RequestService;