import React from 'react';
import { useEffect } from 'react';
import url, { invUrl } from '../url';
import useAuth from '../hooks/useAuth';
import { useState } from 'react';
import { View } from '../components/Button';
import Loader from '../components/Loader';

function Dashboard(){

  // const [sales, setSales] = useState({result:[]});
  const [data, setData] = useState({totalPage:0, previous:{page:0}, next:{page:0},data:[]});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const {id} = useAuth();
  const [loader, setLoader] = useState(false);



  // useEffect(()=>{

  //   if(status){
  //     fetch(`${url}/customer/auth/${id}`,{
  //       method:"GET",
  //       credentials: 'include'
  //   }).then((data)=>data.json()).then((data)=>{
  //       if(data.status === true){
  //         setSales(data.data.sales)
  //       }
  //   });
  // }
    
  // },[status, id]);

  function print(id){

    const w = window.open(`${invUrl}/invoice/${id}`,"myWindow", 'fullscreen="yes"');

    setTimeout(()=>{
      w.close();
    }, 4000);

  }


  useEffect(()=>{
      setLoader(true);
      fetch(`${url}/sale/byCus/${id}?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
          if(data.status === true){
            setLoader(false);
            setData(data.result);
          }
      });
  },[id]);

function getByPage(page){
    fetch(`${url}/sale/byCus/${id}?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

      if(data.status === true){
        setData(data.result);
      }else{
      }
    });
}

const buttons = [];
for(let i = 1; i <= data.totalPage; i++){
    buttons.push(<button key={i} onClick={()=>{getByPage(i); setPage(i)}} className={` ${i=== (data.previous.page+1)?' text-green-400':' text-white' } h-11 w-11 mr-1`}>{i}</button>)
}

  useEffect(()=>{
    window.scrollTo(0,0)

  },[]);

  return(
    <>
    <div className=' w-full  bg-slate-100'>
      <div className=' w-full rounded-md py-10 '>
        <div className=' w-full flex justify-center z-10 absolute top-0 mt-24 '>
          {
              loader?<Loader />:<></>
          }     
        </div>
        {
          (data.data.length >0 && !loader)?
            <table className=' w-full h-max text-center bg-slate-100 '>
              <thead>
                <tr className=' border-b border-slate-500'>
                    <th className=' px-4'>#</th>
                    <th className=' px-4'>Date</th>
                    <th className=' px-4'>Package quantity</th>
                    <th className=' px-4'>Total Price</th>
                    <th className=' px-4'>Discount</th>
                    <th className=' px-4'>Payable</th>
                    <th className=' px-4'>Paid</th>
                    <th className=' px-4'>Reward</th>
                    <th className=' px-4'>Due</th>
                    <th className=' px-4'>Status</th>
                    <th className=' px-4'>Service Provider</th>
                    <th className=' px-4'>Details</th>
                    <th className=' px-4'>Invoice</th>
                </tr>
              </thead>
              <tbody>
                {
                  data.data.map((data, index)=>{

                    const d = new Date(data.date);

                    return(
                      <tr key={index} className=' border-b h-10 border-slate-500'>
                          <td className=' px-4'>{index+1}</td>
                          <td className=' px-4'>{d.toLocaleString()}</td>
                          <td className=' px-4'>{data.cart.length}</td>
                          <td className=' px-4'>{data.totalPrice} ৳</td>
                          <td className=' px-4'>{data.discount} ৳</td>
                          <td className=' px-4'>{data.receivable - data.discount} ৳</td>
                          <td className=' px-4'>{data.received} ৳</td>
                          <td className=' px-4'>{data.reword} ৳</td>
                          <td className=' px-4'>{data.totalPrice - data.discount - data.received} ৳</td>
                          <td className=' px-4'>
                          {
                            (data.status === "delivered")?<span className=' px-2 pb-[2px] bg-green-600 text-white text-xs rounded-full'>delivered</span>:<></>
                          
                          }
                                                    {
                            (data.status === "accepted")?<span className=' px-2  pb-[2px] bg-blue-600 text-white text-xs rounded-full'>accepted</span>:<></>
                          
                          }
                                                    {
                            (data.status === "pending")?<span className=' px-2  pb-[2px] bg-orange-600 text-white text-xs rounded-full'>pending</span>:<></>
                          
                          }
                          </td>
                          <td><View to={`/purchase/${data._id}/vendor`} /></td>
                          <td><View to={"/purchase/"+data._id} /></td>
                          {
                            (data.status === "accepted" || data.status === "delivered")?<td><button onClick={()=>print(data._id)} className=" text-orange-600 text-xl"><i className="fa-solid fa-print"></i></button></td>:<></>
                          }
                          
                      </tr>
                    )
                  })
                }
                <tr>

                </tr>
              </tbody>
            </table>:!loader?<h1 className=' w-full text-center font-bold text-xl text-red-500'>No service is not purchase yet!</h1>:<></>
      }
      </div>
      <div className={` w-auto h-auto sm:h-11 mb-10 mx-auto rounded-bl-md rounded-br-md bg-slate-200 flex flex-col sm:flex-row `}>
        <div className=' flex '>
        {
            (data.previous.page === 0)? <button className=' h-11 px-2 mr-1 text-gray-400 cursor-not-allowed'>Previous</button>:<button onClick={()=>{setPage(data.previous.page); getByPage(data.previous.page); }} className=' h-11 px-2 mr-1'>Previous</button>
        }
            <div  className=' flex flex-row  flex-wrap'>
                {
                    buttons
                }
            </div>
            {
              (data.next.page === 0)?  <button className='h-11 px-2 text-gray-400 cursor-not-allowed'>Next</button>: <button onClick={()=>{setPage(data.next.page); getByPage(data.next.page); }} className='h-11 px-2'>Next</button>
            }
            
        </div>
        <div className=' pl-2 sm:pl-8 py-2 mt-0.5'>Showing {(data.previous.page*data.previous.limit)+1} to { (data.next.page === 0)? data.totalData : (data.previous.page+1)*data.previous.limit} of {data.totalData} entries</div>
      </div>
    </div>  
  </>
  )
}

export default Dashboard;