import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import blogLogo from '../../../assets/images/blogLogo.svg'
import url from '../../../url';

function Nav(){
    const [category, setCategory] = useState('0');
    const [data, setData] = useState([]);
    const [style, setStyle] = useState('-ml-[300px]');

    useEffect(()=>{
        fetch(`${url}/blogCategory`,{method:"GET", credentials:'include', mode:'cors'}).then((data)=>data.json()).then((data)=>{
            setData(data.result.data)
        })
    },[]);


    function setSt(){
        if(style === '-ml-[300px]'){
            setStyle('-ml-[0px]');
        }else{
            setStyle('-ml-[300px]');
        }
    }

    return(<>
        <nav className=" hidden fixed z-10 top-0 left-0  w-full h-[70px] md:px-5 lg:px-10 xl:px-20 bg-slate-100 md:flex items-center justify-between shadow-md">
            <div><Link to='/blog'><img className=" h-[60px] " src={blogLogo} alt=""/></Link></div>
            <div className=" flex gap-4">
                {
                    data.map((data,index)=>{
                        return(
                            <div onMouseEnter={()=> setCategory(data._id)} key={index} className=" w-max h-auto relative font-bold">
                                <span className=" cursor-pointer hover:text-orange-600">{data.name}</span>
                                <div onMouseLeave={()=>setCategory(0)} className={`${(category === data._id)? 'block':'hidden'} w-[200px] h-auto p-2 bg-slate-200 shadow-md absolute z-10 flex flex-col gap-2  top-10 right-0`}>
                                    {  
                                        data.blogSubCategorys.map((data, index)=>{
                                            return(
                                                <Link to={"/blog/Category/"+data._id} className=" pb-2 border-b border-slate-300 text-orange-600 hover:text-slate-500" key={index}>{data.name}</Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </nav>
        <nav className=" md:hidden w-full ">
            <div className=" w-full h-[60px] fixed top-0 left-0 z-50 flex items-center justify-between bg-slate-100 shadow-md px-2">
                <div><Link to='/blog'><img className=" h-[60px] " src={blogLogo} alt=""/></Link></div>
                <button onClick={setSt} className=" text-3xl text-orange-600"><i className="fa-solid fa-bars"></i></button>
            </div>
            <div className={` ${style} w-[300px] h-[90vh] fixed z-10  top-14 left-0 p-5  pb-5 flex flex-col gap-4 bg-slate-100 transition-all duration-700 ease-in-out  `}>
                {
                    data.map((data,index)=>{
                        return(
                            <div onMouseEnter={()=> setCategory(data._id)} onMouseLeave={()=>setCategory(0)} key={index} className=" w-max h-auto relative font-bold">
                                <span className=" text-xl">{data.name}</span>
                                <div onMouseLeave={()=>setCategory(0)} className={`${(category === data._id)? 'block':'hidden'} w-[200px] h-auto p-2 flex flex-col gap-2 `}>
                                    {  
                                        data.blogSubCategorys.map((data, index)=>{
                                            return(
                                                <Link to={"/blog/Category/"+data._id} className=" pb-2 pl-2 text-sm text-orange-600" key={index}>{data.name}</Link>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </nav>
    </>)
}

export default Nav;