import React from 'react';
import { Link } from 'react-router-dom';

function PaymentSuccess(){
    return(
        <div className=' w-full h-4/6 flex flex-col items-center justify-center'>
            <h1 className=' text-center text-lg text-green-600 mb-10'>Payment Successful.</h1>
            <Link className=' px-5 bg-orange-600 text-white rounded-full animate-bounce' to='/dashboard'>Back</Link>
        </div>
    )
}

export default PaymentSuccess;