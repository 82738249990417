import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.svg'
import banner from '../assets/images/banner.png';
import Search from './Search';
import { useSelector } from 'react-redux';
import useAuth from '../hooks/useAuth';
import avatar from '../assets/images/avatar.webp';


function Nav() {

  const [style, setStyle] = useState({nav:'h-24 bg-transparent', location:'text-black', banner:'', search:'placeholder:text-slate-400'});

  const [sideBasr, setSideBasr] = useState('-ml-[500px]');

  const { status } = useAuth();
  const customer = useSelector((state)=>state.customer);
  const cart = useSelector((state)=>state.cart);


  window.addEventListener('scroll',()=>{
      if(window.scrollY > 150){
          setStyle({nav: 'h-16 bg-slate-100 shadow-md', location:'', banner:'', search:'placeholder:text-slate-600 text-slate-600'});
      }else{
          setStyle({nav:'h-24 bg-transparent', location:'text-black', banner:'', search:'placeholder:text-slate-400'});
      }
  });

  return (
    <>
      <div className={`${style.banner}w-full h-[410px] sm:h-600 bg-cover bg-center relative flex items-center justify-center transition-all duration-700 ease-in-out`}>
            <img className=' w-full h-full object-cover absolute mix-blend-overlay' src={banner} alt="" />
            {/* <div className=''>
                <h1 className=' text-white text-2xl sm:text-4xl xl:text-5xl text-center font-bold -mt-20'>Make Your Life Easy...</h1>
                <h1 className=' text-white text-xs sm:text-base  mt-2 pr-10 text-right'>Quality Home Service Means <span className=' text-orange-400'>Easy</span>Sheba.</h1>
                <h1 className=' text-white  text-xs sm:text-xl mt-4 uppercase tracking-[.1rem] sm:tracking-[.5rem]'> Any Service, Anywhere, Any Time.</h1>
            </div> */}
      </div>
      <nav className={`${style.nav} w-full flex px-5 sm:px-16 xl:px-40 fixed top-0 left-0 z-50 transition-all duration-700 ease-in-out `}>
        <div className=' w-full flex items-center justify-between bg-none'>
          <button onClick={()=> setSideBasr('-ml-[0px]')} className=' block md:hidden text-2xl border-none outline-none text-orange-600'><i className="fa-solid fa-bars"></i></button>
            <div><Link to='/'><img className=' w-52' src={logo} alt="" /></Link></div>
            <div className=' hidden w-2/5 md:flex items-center cursor-pointer'>
              <div className={`${style.location} min-w-max pr-10 font-bold `}><i className="fa-solid fa-location-dot text-orange-600"></i> Banasree</div>
              <Search style={style} />
            </div>
            <div className=' flex gap-5'>
                <Link className=' hidden md:block text-orange-600 hover:text-orange-500 font-bold' to="/allServices">All Services</Link>
                {
                  status?<Link className='hidden md:block' to="/dashboard"><img className=' h-10 rounded-full' src={(customer.img !== undefined)?customer.img:avatar} alt="" /></Link>:<Link className=' hidden md:block text-orange-600 hover:text-orange-500 font-bold' to="/login">Log In</Link>
                }
            </div>
            <Link to="/cart" className=' block md:hidden text-2xl border-none outline-none text-orange-600 relative'><i className="fa-solid fa-cart-shopping"></i>
              {
                (cart.length > 0)?<div className=' absolute top-0 left-8 -ml-4 w-4 h-4 rounded-full bg-red-700 text-white text-xs flex items-center justify-center'><span>{cart.length}</span></div>:<></>
              }
            </Link>
        </div>
      </nav>
      <div className={` ${sideBasr} block md:hidden bg-slate-100 w-[300px] sm:w-[400px] h-screen p-4 fixed top-0 left-0 z-50  transition-all duration-700 ease-in-out`}>
        <button onClick={()=>setSideBasr('-ml-[500px]')} className=' absolute top-0 right-3 text-3xl text-orange-600'><i className="fa-solid fa-xmark"></i></button>
        <div className=' flex flex-col items-center gap-4'>
          <div><Link to='/'><img className=' w-52' src={logo} alt="" /></Link></div>
          <Search style={{search:'placeholder:text-slate-600 text-slate-600'}} />
          <div className={` min-w-max font-bold cursor-pointer `}><i className="fa-solid fa-location-dot text-orange-600"></i> Banasree</div>
          <Link className='text-orange-600 hover:text-orange-500 font-bold' to="/allServices">All Services</Link>
          {
            status?<Link to="/dashboard"><img className=' h-10 rounded-full' src={(customer.img !== undefined)?customer.img:avatar} alt="" /></Link>:<Link className=' text-orange-600 hover:text-orange-500 font-bold' to="/login">Log In</Link>
          }
        </div>
      </div>
      {/* <Cart /> */}
    </>
  )
}

export default Nav;