import React from 'react';
import { Link } from 'react-router-dom';
import logo2 from '../assets/images/logo2.svg'
import paly from '../assets/images/play.png';
import Chat from './Chat';
function Footer() {

    const date = new Date();


  return (<>
    <div className=' w-full h-auto  py-10 px-5 md:px-10 xl:px-20 2xl:px-40 text-slate-200  bg-black'>
        <h1 className=' text-xl font-bold pb-5'>We are serving in<span className='text-orange-600'>...</span></h1>
        <div className=' pr-0 md:pr-5 xl:pr-72 pb-6'>
            <h1 className=' text-lg'>Dhaka</h1>
            <div>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Mohammadpur</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Farmgate</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Dhanmondi</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Gulshan</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Mirpur</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Motijheel</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Uttara</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Malibag</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Banani</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Mirpur DOHS</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Baridhara DOHS</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Bashundhara R/A</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Khilkhet</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Nikunjo</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Badda</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Bashabo</button>
                <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Jatrabari</button>
            </div>
        </div>
        <div className=' pr-0 md:pr-5 xl:pr-72'>
            <h1 className=' text-lg'>Chittagong</h1>
            <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Nasirabad</button>
            <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Sugandha</button>
            <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Bayejid bostami</button>
            <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Chadgaon</button>
            <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Pachlaish</button>
            <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Pahartali</button>
            <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Khulshi</button>
            <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Kotwali (Chittagong)</button>
            <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Bakalia</button>
            <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Double Mooring</button>
            <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Bandar</button>
            <button className=' text-sm pr-10 text-slate-300 hover:text-orange-600'>Patenga</button>
        </div>
    </div>
    <footer className=' w-full h-auto md:h-80 lg:h-72 flex flex-col md:flex-row items-center md:justify-between xl:px-20 bg-gray-800 '>

        <div className=' w-96 h-full p-4 flex flex-col gap-5 items-center text-white'>
            <img className=' w-40' src={logo2} alt="" />
            <h1>Connect With Our Social Media</h1>
            <div className=' flex gap-4'>
                <a className=' text-3xl hover:text-orange-600 transition-colors' href="https://www.facebook.com/EasyShebaPlatform"><i className="fa-brands fa-facebook-square"></i></a>
                <a className=' text-3xl hover:text-orange-600 transition-colors' href="https://easysheba.com/"><i className="fa-brands fa-youtube-square"></i></a>
                <a className=' text-3xl hover:text-orange-600 transition-colors' href="https://easysheba.com/"><i className="fa-brands fa-instagram-square"></i></a>
                <a className=' text-3xl hover:text-orange-600 transition-colors' href="https://www.linkedin.com/company/31311047/admin/"><i className="fa-brands fa-linkedin"></i></a>
            </div>
            <h1 >Download Our App</h1>
            <a href="https://play.google.com/store/apps/details?id=multiplexer.lab.easysheba" target="_blank" rel="noreferrer" ><img className=' w-32' src={paly} alt="" /> </a>
        </div>
        <div className=' w-80 lg:w-96 h-full p-4 flex flex-col gap-2 items-center md:items-start text-white'>
            <h1 className=' text-xl md:text-lg mt-3 pb-4'>OTHER PAGES</h1>
            <Link to="/about">About Us</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/career">Career</Link>
            <Link to="">Help</Link>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
            <Link to="">Privacy and Policies</Link>
        </div>
        <div className='w-80 lg:w-96 h-full p-4 flex flex-col gap-2 items-center md:items-start text-white'>
            <h1 className=' text-xl md:text-lg mt-3 pb-4'>COMPANIES</h1>
            <a href="http://digitalearning.xyz/" target="_blank" rel="noreferrer">Digital Learning XYZ</a>
            <Link to="">Agriona Food Ltd</Link>
            <Link to="">Bhidu Agro Ltd</Link>
        </div>
        <div className='w-80 lg:w-96 h-full p-4 flex flex-col gap-2 items-center md:items-start text-white'>
            <h1 className=' text-xl md:text-lg mt-3 pb-4'>CONTACT US</h1>
            <div>
                <div className=' w-max float-left pr-2'><i className="fa-solid fa-phone"></i></div>
                <span className=' float-left'><a href="tel:09696-010506"> 09696-010506</a>  <br /><a href="tel:09696-010506"> 01966-050506</a></span>
            </div>
            <div>
                <div className=' w-max float-left pr-2'><i className="fa-solid fa-envelope"></i></div>
                <span className=' float-left'><a href="mailto:info@easysheba.com"> info@easysheba.com</a></span>
            </div>
            <div>
                <div className=' w-max float-left pl-1 pr-2'><i className="fa-solid fa-location-dot"></i></div>
                <span className=' float-left'>House 10 (4th Floor), <br />Main Road, Block C,  <br />  Banasree, Rampura, Dhaka 1219</span>
            </div>
        </div>
    </footer>
    <Chat />
    <div className=' text-sm md:text-base p-4 md:p-0 w-full md:leading-60 bg-slate-600 text-white text-center'><span>Copyright @{date.getFullYear()} | EasySheba Platform Limited | All Rights Reserved</span></div>
    </>)
}

export default Footer;