import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import url from '../url';

function Invoice(){

    const [purchase, setPurchase] = useState({_id:'', cart:[], receivable:0, received:0, due:0, customerName:"", customerEmail:"", customerPhone:"", address:"", totalPrice: 0, discount:0, quantity:0, date:''});
    const {id} = useParams();

    useEffect(()=>{

        fetch(`${url}/sale/f/${id}`,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{
            setPurchase(data.data);

            setTimeout(() => {
                window.print();
            }, 500);
        });

    },[id]);

    const d = new Date(purchase.date);
    const dat = d.toDateString();


    useEffect(()=>{
        window.scrollTo(0,0)
    
      },[]);


    return(
        <div className=" w-full h-full p-10m -mt-14">
            <div className=" w-60 h-60 bg-inv2 bg-center bg-cover "></div>
                <div className=" w-full h-full bg-inv1 bg-center bg-cover">
                <h1 className=" uppercase text-xl font-bold pl-20 pb-10">Invoice</h1>
                <div className=" w-full pl-20 flex justify-between">
                    <div className=" w-96">
                        <h1 className=" font-bold">To:</h1>
                        <h2><span className=" font-bold">Name: </span><span>{purchase.customerName}</span></h2>
                        <h2><span className=" font-bold">Phone:</span> <span>{purchase.customerPhone}</span></h2>
                        <h2><span className=" font-bold">Email: </span> <span>{purchase.customerEmail}</span></h2>
                        <h2><span className=" font-bold">Address: </span> <span>{purchase.address}</span></h2>
                    </div>
                    <div className=" w-96">
                        <h2><span className=" font-bold">Order Date: </span> <span>{dat}</span></h2>
                        <h2><span className=" font-bold">Payment Method: </span> <span>Cash</span></h2>
                    </div>
                </div>

                <table className=" w-full mt-10 border border-black">
                    <thead>
                        <tr className=" border border-black">
                            <th className=" border border-black">SL No.</th>
                            <th className=" border border-black">Item Name</th>
                            <th className=" border border-black">Unit Price</th>
                            <th className=" border border-black">Quantity</th>
                            <th className=" border border-black">Total Price</th>
                        </tr>
                    </thead>
                    <tbody className=" text-center">
                        {
                            purchase.cart.map((data, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td className=" border border-black">{index+1}</td>
                                        <td className=" border border-black">{data.name}</td>
                                        <td className=" border border-black">{data.discountPrice}</td>
                                        <td className=" border border-black">{data.quantity}</td>
                                        <td className=" border border-black">{data.discountPrice * data.quantity}</td>
                                    </tr>
                                )
                            })
                        }
                        <tr>
                            <td colSpan="4" className="border border-black font-bold">Total Price</td>
                            <td className="border border-black">{purchase.receivable}</td>
                        </tr>
                        <tr>
                            <td colSpan="4" className="border border-black font-bold">Discount</td>
                            <td className="border border-black">{purchase.discount}</td>
                        </tr>
                        <tr>
                            <td colSpan="4" className="border border-black font-bold">Total Payable</td>
                            <td className="border border-black">{purchase.receivable - purchase.discount}</td>
                        </tr>
                        <tr> 
                            <td colSpan="4" className="border border-black font-bold">Total Paid</td>
                            <td className="border border-black">{purchase.received}</td>
                        </tr>
                        <tr>
                            <td colSpan="4" className="border border-black font-bold">Total Due</td>
                            <td className="border border-black">{purchase.due - purchase.discount}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    );
}

export default Invoice;