import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import NavHome from '../components/Nav';
import useAuth from '../hooks/useAuth';
import { ADD_QUANTITY, REMOVE_QUANTITY, REMOVE_FROM_CART } from '../store/actions/cart';



function Cart(){

    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const { status } = useAuth();
    const navigate = useNavigate();

    useEffect(()=>{

        let d  = cart.map((data)=>{
            return(data.discountPrice*data.quantity);
        });

        setTotal(d.reduce((a, b) => a + b, 0));


    },[cart]);




    useEffect(()=>{
        window.scrollTo(0,0)
    
      },[]);



    return(<>
        <NavHome />
        <div className=' w-full h-auto md:px-10 py-40 overflow-auto'>
            {
                (cart.length > 0)?

                <div className=' sm:w-full py-10'>
                    <table className=' w-full text-start text-sm sm:text-base'>
                        <thead>
                            <tr className=' border-b border-slate-400 text-xs md:text-base'>
                                <td className='font-bold md:p-2 px-4'>#</td>
                                <td className='font-bold md:p-2 text-center md:text-left'>Name</td>
                                <td className='font-bold md:p-2 text-center md:text-left'>Price</td>
                                <td className='font-bold md:p-2 text-center md:text-left'>Quantity</td>
                                <td className='font-bold md:p-2 pl-2 text-center md:text-left '>Total Price</td>
                                <td className='font-bold md:p-2  text-center md:text-left'>Remove</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cart.map((data,index)=>{
                                    return(
                                        <tr key={index} className=' border-b border-slate-400 h-12 text-xs md:text-base'>
                                            <td className=' md:p-2 px-4'>{index+1}</td>
                                            <td className=' md:p-2'>{data.name}</td>
                                            <td className=' md:p-2'>{data.discountPrice}</td>
                                            <td className=' h-full p-0 flex justify-center md:justify-start'>
                                                <div className=' w-5 mt-2 md:mt-6 sm:w-28 md:w-20 border-2 border-orange-600 rounded-md flex flex-col md:flex-row justify-between'>
                                                    <button onClick={()=> dispatch(REMOVE_QUANTITY({id:data.id}))} className=' bg-orange-600 text-white md:px-[5px]'><i className="fa-solid fa-minus"></i></button>
                                                    <span className=' text-orange-600 text-center'>{data.quantity}</span>
                                                    <button onClick={()=> dispatch(ADD_QUANTITY({id:data.id}))} className=' bg-orange-600 text-white md:px-[5px]'><i className="fa-solid fa-plus"></i></button>
                                                </div>
                                            </td>
                                            <td className=' md:p-2'>{data.discountPrice}*{data.quantity} = {data.discountPrice*data.quantity}</td>
                                            <td className=' md:p-2'>
                                                <button onClick={()=> dispatch(REMOVE_FROM_CART({id:data.id}))} className=' text-red-600 text-2xl p-4 pl-6'><i className="fa-solid fa-xmark"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className=' hidden md:block'></td>
                                <td className=' w-40 md:w-60 pt-4 text-xs md:text-base '>
                                    <span className=' font-bold'>Total Payable: </span>
                                    <span>{total}</span>
                                </td>
                                <td></td>
                            </tr>

                        </tbody>
                        
                    </table>
                    <div className=' w-max mt-16 mx-auto flex justify-between'>
                        <Link className=' bg-orange-700 px-6 rounded-tl-md rounded-bl-md text-white' to="/allServices">Add More</Link>
                        {
                            status?<Link to="/checkout" className=' bg-blue-700 px-6 rounded-tr-md rounded-br-md text-white' >Checkout</Link>:<button onClick={()=>{alert("You are not logged in! Please login to checkout.");  navigate("/login"); }} className=' bg-blue-700 px-6 rounded-tr-md rounded-br-md text-white' >Checkout</button>
                        }
                    </div>
                </div>
                :
                <div className=' flex flex-col justify-center'>
                    <h1 className=' text-center text-3xl text-orange-600'>Cart is empty. Please add some product on cart.</h1>
                    <Link to="/" className=' text-xl bg-orange-600 text-white px-2 pb-[1px] mx-auto  rounded-full mt-16 animate-bounce '>Back To Home</Link>
                </div>
            }
        </div>
        <Footer />
        </>)
}

export default Cart;