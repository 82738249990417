import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import url from '../url';

function Career(){

    const [data, setData] = useState([]);
    const { id } = useParams();


    useEffect(()=>{

        fetch(`${url}/vacancy/${id}?page=${1}&limit=${0}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setData(data.data[0]);
            }
        });
    
    },[id]);

    useEffect(()=>{
        window.scrollTo(0,0)
    },[]);

    return(
        <>
             <div className=' -mt-48  w-full h-auto'>
                <div className='  w-full relative h-auto pt-20 px-4 sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px]  mx-auto mt-5 xl:mt-16 bg-slate-100'>
                    <h1 className=" w-full text-center text-2xl font-bold"><span className=" border-b border-orange-600"> Job Details</span></h1>
                    <div className=" w-full text-left mt-10 relative" dangerouslySetInnerHTML={{ __html: data.description }}></div>
                   <div className=" w-full flex items-center justify-center gap-4 mt-20 pb-10">
                   <Link to={"/career"} className="w-[100px] block px-2 bg-blue-400 text-white text-center rounded-full">Back</Link>
                   <Link to={"/career/"+data.name} className="w-[100px] block px-2 bg-orange-600 text-white  text-center rounded-full">Apply Now</Link>
                   </div>
                    
                    
                </div>
             </div>
        </>
    )
}

export default Career;