import React from 'react';
import { useEffect } from 'react';
import Footer from '../components/Footer';
import Nav from '../components/Nav';

function About(){
    useEffect(()=>{
        window.scrollTo(0,0)
    },[]);

    return(
        <>
         <Nav />
            <div className=' p-4 mt-[100px] lg:px-40'>
                <h1 className=' text-2xl font-bold text-orange-600 text-center'>
                    <span>About Us</span>
                </h1>
                <p className=' text-justify py-4'>
                <b>‘EasySheba’</b> is the easiest platform to reach a one-stop solution to sort all your corporate and home services requirements ranging from construction to maintenance. We are always here to serve quickly, professionally & conveniently. 
                </p>
                <p className=' text-justify py-4'>
                To get different kinds of daily and regular services, customers have to go to the shops themselves and pay the required amount of money to get the service and most of the time they have to pay more money than usual. Even many people do not know, whether the person who came to work is actually an expert or not. This project is able to relieve the hassle beside all of these things are less troubled. There are so few frequent services available on one platform. In this service customers will be able to access the service from this app or website in their own home where the service will be provided by the selected professionals.  
                </p>
                <p className=' text-justify py-2 font-bold'>The benefits of the initial phase will be: </p>
                <p>1. IT Service </p>
                <p>2. CCTV Camera </p>
                <p>3. Intercom/ PABX </p>
                <p>4. Attendance System </p>
                <p>5. Cleaning Service </p>
                <p>6. Appliance Repair Service </p>
                <p>7. Shifting Service </p>
                <p>8. Interior Design</p>
            </div>
            <Footer />
        </>
    )
}

export default About;