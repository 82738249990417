import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import url from "../../url";
import Nav from "./components/Nav";
import Loader from '../../components/Loader';

function Blog(){
    const [data, setData] = useState({img:"", name:"", description:"", date: new Date()});
    const { id } = useParams();
    useEffect(()=>{

        fetch(`${url}/blog/${id}`,{
            method:"GET",
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setData(data.data)
            }
        });
    
      },[id]);

      

    return(
        <>
            <Nav />
            {
                (data.description === "")?
                <div className=' w-full h-auto absolute top-1/3 left-0 flex items-center justify-center'>
                    <Loader />
                </div>:<></>
            }
            
            <div className='  p-4 pt-20 md:pt-4'  dangerouslySetInnerHTML={{ __html: data.description }}></div>
        </>
    )
}

export default Blog;