import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import url from '../url';
import { Alert1, Alert2, AlertContainer } from '../components/Alert';

function Login(){

    const [value, setValue] =  useState({name:'', email:'', phone:'', address:'', password:'',retypePassword:''});
    const [alert, setAlert] = useState([]);
    const navigate = useNavigate();

    const {status} = useAuth();

    useEffect(()=>{
        if(status){
            navigate('/dashboard');
        }
    },[status, navigate]);

    function input(e){
        setValue({...value, [e.target.name]:e.target.value});
    }



    function send(){

        if( value.name === '' || value.email === ''|| value.phone === ''|| value.address === ''|| value.password === ''){
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message="All fields are required!" />]);
        }else{
                if(value.retypePassword === value.password){
                    const formData = new FormData();
        
                    formData.append('name',value.name);
                    formData.append('email',value.email);
                    formData.append('phone',value.phone);
                    formData.append('address',value.address);
                    formData.append('password',value.password);
                
                    fetch(`${url}/customer`,{
                        method:"POST",
                        body:  formData,
                        credentials: 'include'
                    }).then((data)=>data.json()).then((data)=>{
            
                    if(data.status === true){

                        setAlert((alert)=>[...alert, <Alert1 key={ Date.now()} title="Successful" message={data.message} />]);
                        setTimeout(()=>{
                            navigate('/dashboard')
                        },2000);

                    }else{
                        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
                    }
                    });
                }else{
                    setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message="Retyped password not matched!" />]);
                }
        }
    }
     



      useEffect(()=>{
        window.scrollTo(0,0)
    
      },[]);
    

    return(
        <div className=' p-4 -mt-10 mb-20'>
            <div className= " absolute top-16 right-2 ">
                <AlertContainer>
                    {alert}
                </AlertContainer>
            </div>
            <form className='  md:w-[600px] h-auto mx-auto p-4 pb-10 flex flex-col rounded-md shadow-md bg-slate-300 relative ' >
                <h1 className=' text-center text-xl'> Sign Up</h1>

                <label htmlFor='name' >Name:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="text" name="name" id="name" value={value.name} placeholder='Enter your name' /> 

                <label htmlFor='email' >Email:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="email" name="email" id="email" value={value.email} placeholder='Enter your email' />

                <label htmlFor='phone' >Phone:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="number" name="phone" id="phone" value={value.phone} placeholder='Enter your phone' /> 

                <label htmlFor='address' >Address:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="text" name="address" id="address" value={value.address} placeholder='Enter your address' />

                <label htmlFor='password' >Password:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="password" name="password" id="password" value={value.password} placeholder='Enter password' /> 

                <label htmlFor='retypePassword' >Retype Password:</label>
                <input onChange={(e)=>input(e)} className=" pl-2 mb-2  rounded-sm  border-none outline-none text-slate-500" type="password" name="retypePassword" id="retypePassword" value={value.retypePassword} placeholder='Retype your password' />

                <button onClick={send} className=' bg-orange-600 w-20 text-white mt-4 rounded-sm' type='button'>Sign Up</button>

                <div className=' w-max absolute right-4 bottom-4 '>
                    <span>Have an account?</span>
                    <Link className=' text-orange-600' to="/login"> Log In</Link>
                </div>
            </form>
        </div>
    )
}

export default Login;