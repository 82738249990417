import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import url from '../url';

function ChangeInfo(){

    const [value, setValue] =  useState({name:'', email:'', phone:'', address:'',});
    const navigate = useNavigate();

    function input(e){
        setValue({...value, [e.target.name]:e.target.value});
    }


    const { id } = useAuth();

    useEffect(()=>{
        fetch(`${url}/customer/auth/${id}`,{
            method:"GET",
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setValue({name:data.data.name, email:data.data.email, phone:data.data.phone, address:data.data.address});
            }
        });
    },[id]);

    function file(e){
        setValue({...value, "photo":e.target.files[0]})
    }


    function send(){


        const formData = new FormData();
    
        formData.append('name',value.name);
        formData.append('email',value.email);
        formData.append('phone',value.phone);
        formData.append('address',value.address);
        formData.append('password',value.password);
        formData.append('photo',value.photo);
    
        fetch(`${url}/customer/${id}`,{
          method:"PUT",
          body:  formData,
          credentials: 'include'
      }).then((data)=>data.json()).then((data)=>{
          if(data.status === true){
            navigate('/logIn');
          }
        });



      }

      useEffect(()=>{
        window.scrollTo(0,0)
    
      },[]);
    

    return(
        <div className=' p-4  mb-20'>
            <form className='  md:w-[600px] h-auto mx-auto p-4 pb-10 flex flex-col rounded-md shadow-md bg-slate-300 relative ' >
                <h1 className=' text-center text-xl'> Change Info</h1>

                <label htmlFor='name' >Name:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="text" name="name" id="name" value={value.name} placeholder='Enter your name' /> 

                <label htmlFor='email' >Email:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="email" name="email" id="email" value={value.email} placeholder='Enter your email' />

                <label htmlFor='phone' >Phone:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="number" name="phone" id="phone" value={value.phone} placeholder='Enter your phone' /> 

                <label htmlFor='address' >Address:</label>
                <input onChange={(e)=>input(e)} className=" pl-2 mb-2  rounded-sm  border-none outline-none text-slate-500" type="text" name="address" id="address" value={value.address} placeholder='Enter your address' />

                <label htmlFor='image' >Photo:</label>
                <input onChange={(e)=>file(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="file" name="image" id="image" placeholder='Enter your address' />

                <button onClick={send} className=' bg-orange-600 w-20 text-white mt-4 rounded-sm' type='button'>Save</button>

            </form>
        </div>
    )
}

export default ChangeInfo;