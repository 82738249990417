import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import url from '../url';

import { ADD_ALL_CATEGORY } from '../store/actions/category';
import { ADD_CUSTOMER } from '../store/actions/customer';
import { ADD_ALL_FOR_HOME } from '../store/actions/forHome';
import { ADD_ALL_TRANDING } from '../store/actions/tranding';
import useAuth from "./useAuth";




function useFirstLoad(){
    const dispatch = useDispatch();

    const {status, id} = useAuth();

    useEffect(()=>{

      fetch(`${url}/serviceGroup`,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{
  
        if(data.status === true){
          dispatch( ADD_ALL_CATEGORY(data.result.data));
        }

      });

      
      fetch(`${url}/service/h`,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{
        
        if(data.status === true){
          dispatch( ADD_ALL_FOR_HOME(data.data));
        }

      });

    fetch(`${url}/service/t`,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{

      if(data.status === true){
        dispatch( ADD_ALL_TRANDING(data.data));
      }

    });




      if(status){
          fetch(`${url}/customer/auth/${id}`,{
            method:"GET",
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
              dispatch( ADD_CUSTOMER(data.data));
            }
        });
      }

    },[dispatch, id , status])
}

export default useFirstLoad;