import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, NavLink } from 'react-router-dom';
import { Link, Element, Events, scroller } from 'react-scroll';
import Cart from '../components/Cart';
import Faq from '../components/Faq';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import url from '../url';
import { ADD_RECENTLY_VIEWS } from '../store/actions/RecentlyViews';


function ViewService() {

    const [service, setService] = useState({banner:{img:""}, includes:[],excludes:[], faqs:[], subServices:[], details:{html:""}});
    const { id } = useParams();
    const dispatch = useDispatch();


    useEffect(()=>{
        fetch(`${url}/service/${id}`,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{
          
          setService(data.data);
          dispatch(ADD_RECENTLY_VIEWS(data.data));
        
        });

        },[id, dispatch]);


    function scrollTo() {
      scroller.scrollTo('scroll-to-element', {
      duration: 800,
      delay: 0,
      offset: -100,
      smooth: 'easeInOutQuart'
      })
    }
  

    useEffect(()=>{
      return ()=>{
          Events.scrollEvent.remove('begin');
          Events.scrollEvent.remove('end');
      }
    });

    useEffect(()=>{
      window.scrollTo(0,0)
  
    },[]);

  return (
    <>
        <Nav />
        <div className=" w-full h-[500px] block relative">
            <img className=' w-full h-full object-cover absolute mix-blend-overlay' src={service.banner.img} alt="" />
            <div className='w-full h-full absolute top-0 left-0 px-5 sm:px-10 md:px-40 bg-slate-600/40'>
              <div className=' w-full md:w-96 h-full text-white pt-24 '>
                <h1 className='text-white text-xl md:text-3xl font-bold my-4'>{service.name}</h1>
                <div className=' w-max px-2 py-1 bg-orange-500 rounded-md'>
                  <span className=' md:text-lg'><i className="fa-solid fa-star"></i></span>
                  <span className=' text-lg md:text-xl'> 4.97</span>
                  <span className=' md:text-lg'> out of</span>
                  <span className=' text-lg md:text-xl'> 5</span>
                </div>
                <div className=' pt-4'>
                  {
                    service.includes.map((data,index)=>{
                      return(
                        <h1 key={index} className=' flex items-center gap-2'>
                          <span className=' text-xl text-orange-500'><i className="fa-solid fa-check"></i></span>
                          <span className=' text-lg'>{data.name}</span>
                      </h1>
                      )
                    })
                  }
              </div>
              </div>
            </div>
        </div>
        {
          //--------------------------------------------------------------------
        }
        <div className=' w-full h-max pt-10 pl-4 flex justify-center '>
                <div className=" lg:w-72 xl:w-44 h-auto  relative border-r border-slate-400">
                    <div className=' w-full hidden md:flex flex-col h-max sticky top-16 '>
                        <Link activeClass="active" className=" flex items-center w-full my-1 px-2 text-slate-700 cursor-pointer" to="services" spy={true} smooth={true} offset={-100} duration={500} >
                            <span className=' text-base sm:text-xl font-bold'>Services</span>
                        </Link>

                        {
                          (service.includes.length > 0 && service.includes.length > 0)?
                          <Link activeClass="active" className=" flex items-center w-full my-1 px-2 text-slate-700 cursor-pointer" to="overview" spy={true}   smooth={true} offset={-100} duration={500} >
                            <span className=' text-base sm:text-xl font-bold'>Overview</span>
                          </Link>:<></>
                        }
                        {
                          (service.faqs.length > 0)?
                          <Link activeClass="active" className=" flex items-center w-full my-1 px-2 text-slate-700 cursor-pointer" to="faq" spy={true} smooth={true} offset={-100} duration={500} >
                              <span className=' text-base sm:text-xl font-bold'>FAQ</span>
                          </Link>:<></>
                        }
                        {
                          (service.details.html !== undefined)?
                          <Link activeClass="active" className=" flex items-center w-full my-1 px-2 text-slate-700 cursor-pointer" to="details" spy={true} smooth={true} offset={-100} duration={500} >
                          <span className=' text-base sm:text-xl font-bold'>Details</span>
                          </Link>:<></>
                        }
                        <Link activeClass="active" className=" flex items-center w-full my-1 px-2 text-slate-700 cursor-pointer" to="review" spy={true} smooth={true} offset={-100} duration={500} >
                            <span className=' text-base sm:text-xl font-bold'>Review</span>
                        </Link>
                    </div>

                </div>

                <div className=' w-full md:w-3/5 h-auto p-4 '>
                  <Element name="services" className=" h-auto pb-20" >
                      <h1 className=' text-2xl md:text-4xl pb-4'> Select A Service</h1>
                      <div className=' w-full bg-black p-4 rounded-md flex flex-wrap gap-4 '>
                        {
                          service.subServices.map((data, index)=>{
                            return(
                              <NavLink key={index} to={'/package/'+data._id}>
                                  <div key={index} className=' w-max px-2 py-[2px]  text-white glow rounded-md text-sm md:text-lg'>{data.name}</div>
                              </NavLink>

                            )
                          })
                        }
                      </div>
                  </Element>
                  <Element name="overview" className=" h-auto pb-20" >
                  {
                    (service.includes.length > 0 && service.includes.length > 0)?
                    <h1 className=' text-2xl md:text-4xl pb-4'>Service Overview</h1>:<></>
                  }
                      
                      <div>
                      {
                        (service.includes.length > 0)?
                        <div>
                          <h1 className=' text-xl md:text-2xl pb-4 mt-6'>What's Included?</h1>
                          <ol className=' pl-6 text-sm md:text-lg list-disc'>
                            {
                              service.includes.map((data,index)=>{
                                return(
                                  <li key={index}>{data.name}</li>
                                )
                              })
                            }
                          </ol>
                        </div>:<></>
                      }
                      {
                        (service.includes.length > 0)?
                        <div>
                          <h1 className=' text-xl md:text-2xl pb-4 mt-10'>What's Excluded?</h1>
                          <ol className=' pl-6 text-sm md:text-lg list-disc'>
                            {
                              service.excludes.map((data,index)=>{
                                return(
                                  <li key={index}>{data.name}</li>
                                )
                              })
                            }
                          </ol>
                        </div>:<></>
                      }
         
                      </div>
                  </Element>
                  {
                    (service.faqs.length > 0)?
                      <Element name="faq" className=" h-auto pb-20" >
                          <h1 className=' text-2xl md:text-4xl pb-4'>FAQ</h1>
                          <Faq data={service.faqs} />
                      </Element>:<></>
                  }
                  {
                    (service.details.html !== undefined)?
                      <Element name="details" className=" h-auto pb-20" >
                          <h1 className=' text-2xl md:text-4xl pb-4'>Details</h1>
                          <div className=' flex flex-wrap gap-6'>
                            <div className=' p-4'  dangerouslySetInnerHTML={{ __html: service.details.html }}>

                            </div>
                          </div>
                      </Element>:<></>
                  }
                  <Element name="review" className=" h-auto pb-20" >
                      <h1 className=' text-2xl md:text-4xl pb-4'>Review</h1>
                      <div className=' pl-4 flex flex-wrap gap-6'>
                      {/* w-40 md:w-48 lg:w-60 xl:w-80 grow xl:grow-0  */}
                        <div  className=' w-full'>
                          <h1 className=' md:text-xl'>Review for {service.name}</h1>
                          <div className=' w-full h-auto flex gap-4 flex-col sm:flex-row'>
                            <div>
                              <h1 className=' text-4xl'>4.87</h1>
                              <div className=' text-yellow-500 text-xl'>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-regular fa-star-half-stroke"></i>
                              </div>
                            </div>
                            <div className=' mt-5 flex flex-col gap-1'>
                              <div className=' w-[200px] h-3 bg-slate-200 rounded-full'>
                                <div className=' w-[180px] h-3 bg-green-600 rounded-l-full'></div>
                              </div>
                              <div className=' w-[200px] h-3 bg-slate-200 rounded-full'>
                                <div className=' w-[110px] h-3 bg-lime-500 rounded-l-full'></div>
                              </div>
                              <div className=' w-[200px] h-3 bg-slate-200 rounded-full'>
                                <div className=' w-[60px] h-3 bg-yellow-500 rounded-l-full'></div>
                              </div>
                              <div className=' w-[200px] h-3 bg-slate-200 rounded-full'>
                                <div className=' w-[20px] h-3 bg-orange-400 rounded-l-full'></div>
                              </div>
                              <div className=' w-[200px] h-3 bg-slate-200 rounded-full'>
                                <div className=' w-[5px] h-3 bg-red-600 rounded-l-full'></div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                  </Element>
                </div>

            </div>
            <Cart />
        <Footer />
    </>
  )
}

export default ViewService;