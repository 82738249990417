import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import useAuth from '../hooks/useAuth';
import Search from './Search';
import avatar from '../assets/images/avatar.webp';
// import Cart from './Cart';

function NavHome() {

  const [sideBasr, setSideBasr] = useState('-ml-[500px]');
  const { status } = useAuth();
  const customer = useSelector((state)=>state.customer);
  const cart = useSelector((state)=>state.cart);


  return (
    <>
      <nav className={` h-16 bg-slate-100 shadow-md w-full flex px-5 sm:px-16 xl:px-40 fixed top-0 left-0 z-50 transition-all duration-700 ease-in-out `}>
        <div className=' w-full flex items-center justify-between bg-none'>
          <button onClick={()=> setSideBasr('-ml-[0px]')} className=' block md:hidden text-2xl border-none outline-none text-orange-600'><i className="fa-solid fa-bars"></i></button>
            <div><Link to='/'><img className=' w-52' src={logo} alt="" /></Link></div>
            <div className=' hidden w-2/5 md:flex items-center cursor-pointer'>
              <div className={` text-black min-w-max pr-10 font-bold `}><i className="fa-solid fa-location-dot text-orange-600"></i> Banasree</div>
              <Search style={{search:'placeholder:text-slate-600 text-slate-600'}} />
            </div>
            <div className=' flex gap-5 items-center'>
                <Link className=' hidden md:block text-orange-600 hover:text-orange-500 font-bold' to="/allServices">All Services</Link>
                {
                  status?<Link className='hidden md:block' to="/dashboard"><img className=' h-10 rounded-full' src={(customer.img !== undefined)?customer.img:avatar} alt="" /></Link>:<Link className=' hidden md:block text-orange-600 hover:text-orange-500 font-bold' to="/login">Log In</Link>
                }
                
            </div>
            <Link to="/cart" className=' block md:hidden text-2xl border-none outline-none text-orange-600 relative'><i className="fa-solid fa-cart-shopping"></i>
              {
                (cart.length > 0)?<div className=' absolute top-0 left-8 -ml-4 w-4 h-4 rounded-full bg-red-700 text-white text-xs flex items-center justify-center'><span>{cart.length}</span></div>:<></>
              }
            </Link>
        </div>
      </nav>
      <div className={` ${sideBasr} block md:hidden bg-slate-100 w-[300px] sm:w-[400px] h-screen p-4 fixed top-0 left-0 z-50  transition-all duration-700 ease-in-out`}>
        <button onClick={()=>setSideBasr('-ml-[500px]')} className=' absolute top-0 right-3 text-3xl text-orange-600'><i className="fa-solid fa-xmark"></i></button>
        <div className=' flex flex-col items-center gap-4'>
          <div><Link to='/'><img className=' w-52' src={logo} alt="" /></Link></div>
          <Search style={{search:'placeholder:text-slate-600 text-slate-600'}} />
          <div className={` min-w-max font-bold cursor-pointer `}><i className="fa-solid fa-location-dot text-orange-600"></i> Banasree</div>
          <Link className='text-orange-600 hover:text-orange-500 font-bold' to="/allServices">All Services</Link>
          {
            status?<Link to="/dashboard"><img className=' h-10 rounded-full' src={(customer.img !== undefined)?customer.img:avatar} alt="" /></Link>:<Link className=' text-orange-600 hover:text-orange-500 font-bold' to="/login">Log In</Link>
          }
        </div>
      </div>
      {/* <div className=" w-full h-auto p-6">
        <div className=" w-full h-[600px] bg-slate-500 absolute top-24 z-50"></div>
      </div> */}
    </>
  )
}

export default NavHome;