import React, { useState } from 'react'
import url from '../url';
import { Link } from 'react-router-dom';

function Search({style}) {
    const [search, setSearch] = useState('');
    const [data, setData] = useState({totalPage:0, previous:{page:0}, next:{page:0},data:[]});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(8);
    const [stl, setStl] = useState({display:'none'});






    function get(){

      if(search !== ''){
        fetch(`${url}/service/search?page=1&limit=${limit}&search=${search}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

          if(data.status === true){
            setData(data.result);
            setStl({display:'block'});
          }else{
          }
        });
    
      }
    }

    function getByPage(page){
      if(search !== ''){
        fetch(`${url}/service/search?page=${page}&limit=${limit}&search=${search}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

          if(data.status === true){
            setData(data.result);
            setStl({display:'block'});
          }else{
          }
        });
    
      }
    }

  const buttons = [];
  for(let i = 1; i <= data.totalPage; i++){
      buttons.push(<button key={i} onClick={()=>{getByPage(i); setPage(i)}} className={` ${i=== (data.previous.page+1)?' text-green-400':' text-white' } h-11 w-11 mr-1`}>{i}</button>)
  }


  function hide(){
    setSearch('');
    setStl({display:'none'});
  }


  return (<>
          <div style={stl} className=' w-[320px] md:w-[740px] lg:w-[1000px] h-[460px] md:h-[600px] fixed top-24 md:left-[6%] xl:left-[20%] p-6 pb-20 '>
          <div className=' w-full h-full bg-slate-300 rounded-tl-md rounded-tr-md'>
            <div onClick={()=> setStl({display:'none'})} className='w-max h-max cursor-pointer text-4xl font-bold text-red-600 hover:text-red-700 absolute right-9'><i className="fa-solid fa-xmark"></i></div>
            <div className=' w-full h-full p-6 flex gap-6 flex-wrap overflow-auto'>
                {
                  data.data.map((data,index)=>{
                      return(
                          <Link onClick={hide} key={index} to={"/service/"+data._id}>
                              <div key={index}  className=' w-40 md:w-48 lg:w-48 grow xl:grow-0 '>
                                  <img className=' rounded-md' src={data.image.img} alt="" />
                                  <h1 className=' text-base lg:text-xl text-center'>{data.name}</h1>
                              </div>
                          </Link>
                      )
                  })
                }

            </div>
          </div>
          <div className={` w-full h-auto sm:h-11 mb-10 bg-dark-blue-1 mx-auto rounded-bl-md rounded-br-md text-white flex flex-col sm:flex-row `}>
            <div className=' flex '>
            {
                (data.previous.page === 0)? <button className=' h-11 px-2 mr-1 text-gray-400 cursor-not-allowed'>Previous</button>:<button onClick={()=>{setPage(data.previous.page); getByPage(data.previous.page); }} className=' h-11 px-2 mr-1'>Previous</button>
            }
                <div  className=' flex flex-row  flex-wrap'>
                    {
                        buttons
                    }
                </div>
                {
                  (data.next.page === 0)?  <button className='h-11 px-2 text-gray-400 cursor-not-allowed'>Next</button>: <button onClick={()=>{setPage(data.next.page); getByPage(data.next.page); }} className='h-11 px-2'>Next</button>
                }
                
            </div>
            <div className=' pl-2 sm:pl-8 py-2 mt-0.5'>Showing {(data.previous.page*data.previous.limit)+1} to { (data.next.page === 0)? data.totalData : (data.previous.page+1)*data.previous.limit} of {data.totalData} entries</div>
          </div>
        </div>
        <div className=' w-full h-10 bg-transparent flex justify-between border-2 rounded-sm border-orange-600'>
            <input onChange={(e)=>setSearch(e.target.value)} className={`${style.search} w-full bg-transparent text-slate-500 border-none outline-none p-2`} type="text" value={search} name="search" placeholder='Find your service here...' />
            <button onClick={get} className=' bg-orange-600 text-white px-4'><i className="fa-solid fa-magnifying-glass"></i></button>
        </div>
  </>)
}

export default  React.memo(Search);