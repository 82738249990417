import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import url from '../../url';
import Nav from './components/Nav';
import Loader from '../../components/Loader';


function BlogHome(){

    const [ category, setCategory] = useState([])

    useEffect(()=>{
        fetch(`${url}/blogCategory`,{method:"GET", credentials:'include', mode:'cors'}).then((data)=>data.json()).then((data)=>{
            setCategory(data.result.data)
        })
    },[]);



    return(
        <div>
            <Nav />
            {
                (category.length === 0)?
                <div className=' w-full h-auto absolute top-1/3 left-0 flex items-center justify-center'>
                    <Loader />
                </div>:<></>
            }
            <div className=' w-full h-auto  mt-24 md:px-5 lg:px-10 xl:px-20'>
                {
                    category.map((d1, index)=>{
                        
                        return(
                            <div key={index} className=" pb-10 h-max">
                                <h1 className=" pl-14 md:pl-0 text-3xl py-2 border-b-2 border-slate-200">{d1.name}</h1>
                                <div className=" mt-5 flex flex-wrap gap-5 justify-center md:justify-start">
                                    {
                                            
                                        d1.forHome.map((data, index)=>{
                                            const d = new Date(data.date);
                                            if(index === 7){
                                                return(<></>)
                                            }
                                            return(
                                                <Link key={index} to={"/blog/post/"+data._id}>
                                                    <div className=" w-[250px] ">
                                                        <div className=" w-full h-auto relative">
                                                            <div className=" w-[140px] pl-2 absolute top-0 left-0 bg-blue-300 text-white rounded-tl-md rounded-br-md">{d.toDateString()}</div>
                                                            <img className=" rounded-md" src={data.img} alt=""/>
                                                        </div>
                                                        <h1 className=" text-left text-xl pt-1 hover:text-orange-600">{data.name}</h1>
                                                    </div>
                                                </Link>
                                            );
                                        })
                                            
                                    }
                                    
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default BlogHome;