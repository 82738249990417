import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Cart(){
    const cart = useSelector((state)=>state.cart);
    return(
        <Link to="/cart">
            <div className=' hidden md:block fixed bottom-40 right-10 z-10 py-2 px-3 bg-slate-200 rounded-full text-orange-600 text-xl '>
                <i className="fa-solid fa-cart-shopping"></i>
                {
                    (cart.length > 0)?<div className=' absolute top-0 right-0 -ml-4 w-4 h-4 rounded-full bg-red-700 text-white text-xs flex items-center justify-center'><span>{cart.length}</span></div>:<></>
                }
                
            </div>
        </Link>

    )
}

export default Cart;