import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import url from "../../url";
import Nav from "./components/Nav";
import Loader from '../../components/Loader';

function BlogCategory(){
    const [data, setData] = useState([]);
    const { id } = useParams();
    useEffect(()=>{

        fetch(`${url}/blogSubCategory/${id}`,{
            method:"GET",
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setData(data.data.blogs)
            }
        });
    
      },[id]);



      

    return(
        <>
            <Nav />
            {
                (data.length === 0)?
                <div className=' w-full h-auto absolute top-1/3 left-0 flex items-center justify-center'>
                    <Loader />
                </div>:<></>
            }
            <div className=" w-full h-auto px-4 mt-10 py-10 md:px-10 lg:px-40 xl:px-60 flex flex-col gap-5">
                {
                    data.map((data)=>{
                        const d = new Date(data.date);
                        return(
                            <Link key={data._id} to={"/blog/post/"+data._id}>
                                <div className=" w-full mt-5 md:mt-0 md:h-[300px] flex flex-col md:flex-row gap-4 justify-center md:justify-start  ">
                                    <div className=" w-max shrink-0 h-full relative">
                                        <img className=" w-[300px] md:h-full rounded-md " src={data.img} alt=""/>
                                    </div>
                                    <div>
                                        <h1 className=" text-left text-2xl py-2 hover:text-orange-600">{data.name}</h1>
                                        <p className=" xl:pr-40 text-justify">{data.shortDescription}</p>
                                        <h2 className=" text-orange-600">{d.toDateString()}</h2>
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </>
    )
}

export default BlogCategory;