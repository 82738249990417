import React,{useState, useEffect} from 'react';
import { useParams} from 'react-router-dom';
import url from '../url'

function ViewVendor(){
    const { id } = useParams();
    const [vendor, setVendor] = useState([]);
    const [status, setStatus] = useState("panding");

    useEffect(()=>{
        fetch(`${url}/sale/f/${id}`,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setVendor(data.data.vendors);
                setStatus(data.data.status)
            }

        });
      },[id]);
    return(<>
    {
        (status === "panding")?
        <div className=" text-center text-red-600 mt-20">Currently no service provider is assigned for your job.</div>:
        <div className=" w-full h-auto flex flex-wrap item-center justify-center gap-4">
            {
                vendor.map((data,index)=>{
                    return(
                    <div key={index} className=" w-[300px] h-[400px] p-2 bg-slate-100 rounded-md ">
                        <img className="rounded-md" src={data.img} alt=''/>
                        <div className=" pt-4">
                            <div><b>Name: </b><span>{data.name}</span></div>
                            <div><b>Email: </b><a href={`mailto:${data.email}`}>{data.email}</a></div>
                            <div><b>Phone: </b><a href={`tel:+${data.phone}`}>{data.phone}</a></div>
                        </div>
                    </div>
                    );
                })
            }
        </div>
    }
    </>)
}

export default ViewVendor;