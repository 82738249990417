import React from 'react';

import d from '../assets/images/download app.jpg';

function HowVideo() {
  return (
    // <iframe className=' w-full h-full rounded-md' src="https://www.youtube.com/embed/djugeDGBp08" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <img src={d} alt="" />
  )
}

export default React.memo(HowVideo);