import React from 'react';
import useAuth from '../hooks/useAuth';
import { useState } from 'react';
import { useEffect } from 'react';
import url from '../url';
import Loader from '../components/Loader';
import { Alert1, Alert2, AlertContainer } from '../components/Alert';

function Reword(){
    const [data, setData] = useState(0);
    const [code, setCode] = useState();
    const [codeStatus, setCodeStatus] = useState();
    const [alert, setAlert] = useState([]);
    

    const { id } = useAuth();

    useEffect(()=>{
        fetch(`${url}/sale/byCus/${id}?page=${1}&limit=${0}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setData(data.result.data.reduce((total, data)=>{
                    return(total + data.reword)
                },0));
            }
        });
    },[id]);


    useEffect(()=>{
        fetch(`${url}/customer/auth/${id}`,{
            method:"GET",
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setCodeStatus(data.data.rewordCodeVerify)
            }
        });
    },[id]);


    function verify(){
        fetch(`${url}/customer/rewordCodeVerify/${id}`,{
            method:"PUT",
            body: JSON.stringify({"rewordCode":code}),
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{

            if(data.status === true){
                setCodeStatus(true);
                setAlert((alert)=>[...alert, <Alert1 key={ Date.now()} title="Congratulation" message={data.message} />]);
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
            }
        });
    }




    return(<>
            <div className= " absolute top-16 right-2 ">
                <AlertContainer>
                    {alert}
                </AlertContainer>
            </div>
            {
                (codeStatus === true)?
                <div className="  h-52 bg-orange-300/80 relative flex flex-col items-center justify-center rounded-md grow">
                    <div className=" absolute top-0 left-0 w-full h-10 rounded-tl-md rounded-tr-md  text-lg text-center font-bold bg-orange-400 ">Reword Points</div>
                    <div className=' text-3xl scale-150 text-orange-500 '><i className="fa-solid fa-coins"></i></div>
                    <div className=" text-3xl text-center text-orange-500 font-bold pt-4" >{data}</div>
                </div>:<></>
            }
            {
                (codeStatus === false)?
                <div className="  h-52 bg-orange-300/80 relative flex flex-col items-center justify-center rounded-md grow">
                    <div className=" absolute top-0 left-0 w-full h-10 rounded-tl-md rounded-tr-md  text-lg text-center font-bold bg-orange-400 ">Reward Points</div>
                    <div className=' text-3xl scale-150 text-orange-500 '><i className="fa-solid fa-coins"></i></div>
                    <div className=" -mb-5">
                        <div className=' w-full bg-orange-200 border-2 flex border-orange-500/90 mt-5 rounded-sm'>
                            <input onChange={(e)=> setCode(e.target.value)} className=' w-full bg-orange-200 text-red-600 placeholder:text-sm placeholder:text-red-500 px-2 h-7 border-none outline-none' type="text" placeholder='Enter reward card code...'/>
                            <button onClick={verify} className=' bg-orange-500/90 h-full px-2 text-white'><i className=" text-2xl fa-solid fa-paper-plane"></i></button>
                        </div>
                        <p className=" text-xs text-red-600">(Please enter reward card code to access reward point.)</p>
                    </div>
                </div>:<></>
            }
            {
                (codeStatus === undefined)?
                <div className=' w-full h-auto flex items-center justify-center'>
                    <Loader />
                </div>:<></>
            }
            
        </>)
}

export default Reword;