import React from 'react'
import { useState } from 'react';

function Faq({data}) {
    const [activeIndex, setActiveIndex] = useState(0);
    function setindex(newIndex){
        if(newIndex === activeIndex){
            setActiveIndex(-1);
        }else{
            setActiveIndex(newIndex);
        }
    }
  return (
    <div>
        {
            data.map((data, index)=>{
                return(
                    <div className=' pb-4 transition-all ease-in-out duration-700' key={index}>
                        <h1 onClick={()=> setindex(index)} className=' md:text-xl font-bold text-slate-600 cursor-pointer'><span className=' text-orange-600'><i className={(index === activeIndex)? 'fa-solid fa-circle-minus':' fa-solid fa-circle-plus'}></i></span> {data.question}</h1>
                        <div className={` transition-all ease-in-out duration-700 text-justify ${(index === activeIndex)? ' h-max pl-6 p-1 block':' hidden h-6 pl-6 p-1'}`}>{data.answer}</div>
                    </div>
                );
            })
        }
    </div>
  )
}

export default Faq;