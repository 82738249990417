import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import url from '../url';
import { useDispatch } from 'react-redux';
import { ADD_CUSTOMER } from '../store/actions/customer';
import { Alert2, AlertContainer } from '../components/Alert';

function Login(){

    const [value, setValue] =  useState({email:'', password:''});
    const [alert, setAlert] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {status} = useAuth();

    useEffect(()=>{
        if(status){
            navigate('/dashboard');
        }
    },[status, navigate]);


    function input(e){
        setValue({...value, [e.target.name]:e.target.value});
    }



    function send(){
        fetch(`${url}/customer/login`,{
            method:"POST",
            body:  JSON.stringify(value),
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                document.cookie = `user = ${data.data._id} ; max-age=3400; path=/`;
                dispatch( ADD_CUSTOMER(data.data));
                navigate("/dashboard");
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
            }
          });

    }
    

    useEffect(()=>{
        window.scrollTo(0,0)
    
      },[]);
      
    

    return(
        <div className=' p-4 -mt-10 mb-20'>
            <div className= " absolute top-16 right-2 ">
                <AlertContainer>
                    {alert}
                </AlertContainer>
            </div>
            <form className='  md:w-[600px] h-auto mx-auto p-4 pb-10 flex flex-col rounded-md shadow-md bg-slate-300 relative ' >
                <h1 className=' text-center text-xl'> Log In</h1>
                <label htmlFor='email' >Email:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="email" name="email" id="email" value={value.email} placeholder='Enter your email' /> 
                <label htmlFor='password' >Password:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="password" name="password" id="password" value={value.password} placeholder='Enter password' /> 
                <button onClick={send} className=' bg-orange-600 w-20 text-white mt-4 rounded-sm' type='button'>Log In</button>
                <div className=' w-max absolute right-4 bottom-4 '>
                    <span>Not a member?</span>
                    <Link className=' text-orange-600' to="/signup"> SignUp Now</Link>
                </div>
            </form>
        </div>
    )
}

export default Login;