import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { View } from '../components/Button';
import url from '../url';
import Loader from '../components/Loader';

function Career(){

    // const [activeIndex, setActiveIndex] = useState(-1);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [loader, setLoader] = useState(true);

    // function setindex(newIndex){
    //     if(newIndex === activeIndex){
    //         setActiveIndex(-1);
    //     }else{
    //         setActiveIndex(newIndex);
    //     }
    // }

    useEffect(()=>{
        setLoader(true);
        fetch(`${url}/vacancy?page=${1}&limit=${0}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            if(data.status === true){
                setData(data.result.data);
            }
        });
    
    },[]);

    useEffect(()=>{
        window.scrollTo(0,0)
    },[]);

    useEffect(()=>{
        data.map((data)=>{
        
            const d = new Date(data.deadline);
            const dn = Date.now();
            if(d.getTime() > dn){
                setCount((c)=>c+1);
            }
            return 0;
        });
    },[data]);


    let sl = 0;

    return(
        <>
             <div className=' -mt-48  w-full h-auto'>
                {
                    loader?<div className=' w-full absolute flex justify-center mt-20 '><Loader /></div>:<></>
                }
                <div className='  w-full h-auto pt-20 px-4 sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px]  mx-auto mt-5 xl:mt-16 pb-10 bg-slate-100 overflow-auto'>
                    {
                        (count >0)?
                        <h1 className=" w-full text-center text-2xl font-bold"><span className=" border-b border-orange-600">Job Circular</span></h1>:<></>
                    }
                    {
                        (count >0)?
                        <table className=' w-full h-max mt-10 text-center bg-slate-100 '>
                        <thead>
                            <tr className=' border-b border-slate-500'>
                                <th className=' px-4'>SL</th>
                                <th className=' px-4'>Job Title</th>
                                <th className=' px-4'>Vacancy</th>
                                <th className=' px-4'>Details</th>
                                <th className=' px-4'>Deadline</th>
                                <th className=' px-4'>Apply</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                
                            data.map((data, index)=>{

                                const d = new Date(data.deadline);
                                const dn = Date.now();
                                if(d.getTime() > dn){
                                    sl++;
                                return(
                                <tr key={index} className=' border-b h-10 border-slate-500'>
                                    <td className=' px-1'>{sl}</td>
                                    <td className=' px-2'>{data.name}</td>
                                    <td className=' px-2'>{data.vacancy}</td>
                                    <td className='w-full pt-1 flex justify-center items-center'><View to={'/career/details/'+data._id} /></td>
                                    <td className=' px-1'>{d.toDateString()} </td>
                                    <td className=' flex justify-center'><Link to={"/career/"+data.name} className="w-[100px] block px-2 bg-orange-600 text-white rounded-full">Apply Now</Link></td>
                                    
                                </tr>
                                )
                                }else{
                                    return(
                                        <tr key={index}></tr>
                                    )
                                }
                            })
                            }
                            <tr>

                            </tr>
                        </tbody>
                        </table>:
                        <>
                        {
                            loader?<></>:
                            <h1 className=' w-full mt-10 text-center font-bold text-xl text-red-500'>No job available right now!</h1>
                        }
                        </>
                    }
                </div>
             </div>
        </>
    )
}

export default Career;