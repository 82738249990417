import React,{ useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import url from '../url';
import { REMOVE_CART } from '../store/actions/cart';
import { Alert1, Alert2, AlertContainer } from '../components/Alert';


function CheckOut(){

    useEffect(()=>{
        window.scrollTo(0,0)
    
      },[]);

    const [value, setValue ]= useState({cash:true, online:false});
    const cart = useSelector((state) => state.cart);
    const [total, setTotal] = useState(0);
    const [pro, setPro] = useState(0);
    const [profit, setProfit] = useState(0);
    const [coupon, setCoupon] = useState("");
    const [couponS, setCouponS] = useState("");
    const [note, setNote] = useState("");
    const [discount, setDiscount] = useState(0);
    const [alert, setAlert] = useState([]);
    const { id } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{

        let d  = cart.map((data)=>{
            return(data.discountPrice*data.quantity);
        });

        let p = cart.map((data)=>{
            return(data.pPrice*data.quantity);
        });

        setTotal(d.reduce((a, b) => a + b, 0));

        setPro( p.reduce((a, b) => a + b, 0) );

    },[cart]);

    useEffect(()=>{
        setProfit(total - pro)
    },[total,pro])

    function send(){
    if(value.cash === true){
        fetch(`${url}/sale`,{
            method:"POST",
            body:  JSON.stringify({cart, customerId:id, totalPrice:total, discount, coupon:couponS, profit, note}),
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{

            if(data.status === true){
                dispatch( REMOVE_CART());
                navigate('/dashboard'); 
            }
          })
    }else{
        fetch(`${url}/sale/online`,{
            method:"POST",
            body:  JSON.stringify({cart, customerId:id, totalPrice:total, coupon:couponS, discount, profit, note}),
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                dispatch( REMOVE_CART());
                navigate('/dashboard');

                if(data.redirect){
                    window.location.href = data.redirect;
                } 
            }
          })
    }
    }

    function checkCoupon(){

        fetch(`${url}/coupon/check`,{
            method:'POST',
            credentials:'include',
            body:JSON.stringify({"coupon":coupon, cart})
        }).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                if(data.discountType === "TK"){
                    setDiscount(data.discount);
                }else{
                    if(total*(data.discount/100) < data.maxAmount){
                        setDiscount(total*(data.discount/100));
                    }else{
                        setDiscount(data.maxAmount);
                    }
                }
                setCouponS(data.coupon);
                if( discount === 0){
                    setAlert((alert)=>[...alert, <Alert1 key={ Date.now()} title="Congratulation!" message={data.message} />]);
                }else{
                    setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={`You have already got ${discount} tk discount.`} />]);
                }

            }else{
                setDiscount(0);
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
            }
        })
    }


    return(
        <>
            <Nav />
            {
                (cart.length !== 0)?
                <>
                    <div className=' pt-24'></div>
                    <div className= " absolute top-16 right-2 ">
                        <AlertContainer>
                            {alert}
                        </AlertContainer>
                    </div>
                    <div className=' w-[370px] h-auto mx-auto p-2 mb-4 pb-6 rounded-md bg-slate-200'>
                        <div>
                            <div className=" mt-2 text-center">
                                <span className=" font-bold">Payable: </span><span>{total}</span>
                            </div>
                            {
                                (discount > 0)?
                                <>
                                    <div className=" text-center">
                                        <span className=" font-bold">Discount: </span><span>{discount}</span>
                                    </div>
                                    <div className=" mb-2 text-center">
                                        <span className=" font-bold">Payable Now: </span><span>{total - discount}</span>
                                    </div>
                                </>:<></>
                            }
            
                        </div>
                        <div className=' w-72 border-2 flex bg-white border-orange-600 my-5 mx-auto rounded-sm'>
                            <input onChange={(e)=>setCoupon(e.target.value)} className=' w-full px-2 h-5 border-none outline-none text-sm' type="text" placeholder='Enter coupon code...'/>
                            <button onClick={checkCoupon} className=' bg-orange-600 h-full px-2 text-white'>Apply</button>
                        </div>
                        <h2>Please select a payment method.</h2>
                        <form>
                            <input onClick={()=>setValue({cash:true, online:false})} type="radio" name="cash" id='id1' value={value.cash} defaultChecked /><label className=' cursor-pointer' htmlFor='id1'> Cash in delivery</label><br />
                            <input onClick={()=>setValue({cash:false, online:true})} type="radio" name="cash"  id='id2' /><label className=' cursor-pointer' htmlFor='id2'> Online payment</label>
                        </form>
                        <textarea onChange={(e)=> setNote(e.target.value)} className=" w-full mt-5 rounded-md p-2 outline-none border border-orange-600" name="note" id="note" rows="2" placeholder="Any note (date, time)..."></textarea>
                        <button onClick={send} className=' w-full px-2 py-[2px] mt-3 rounded-md bg-blue-600 hover:bg-blue-500 text-white'>Continue</button>
                        <button onClick={()=>navigate('/cart')} className=' w-full px-2 py-[2px] mt-3 rounded-md bg-orange-600 hover:bg-orange-500 text-white'>Back</button>
                    </div>
                </>:
                <div className=' flex flex-col justify-center mt-36 pb-5'>
                    <h1 className=' text-center text-3xl text-orange-600'>Cart is empty. Please add some product on cart.</h1>
                    <Link to="/" className=' text-xl bg-orange-600 text-white px-2 pb-[1px] mx-auto  rounded-full mt-16 animate-bounce '>Back To Home</Link>
                </div>
            }
            <Footer />
        </>
    )
}

export default CheckOut;