const initialState = [] ;

function RecentlyViews(state = initialState, action){
    switch(action.type){
        case 'ADD_RECENTLY_VIEWS' :

            const d = state.filter((data)=>{
                return( data._id === action.data._id);
            });

            if(d.length > 0){
                
            }else{
                state = [action.data, ...state ];
            }

            return state;

        default : return state;
    }

}

export default RecentlyViews;