import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Nav from './Nav';
import Cart from './Cart';

function Layout(){
    return(
        <>
            <Nav/>
            <div className=' w-full h-48 pt-40'></div>
            <Outlet />
            <Footer />
            <Cart />
        </>
    )
}

export default Layout;