import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import url from '../url';
import { Alert1, Alert2, AlertContainer } from '../components/Alert';

function ChangePassword(){

    const [value, setValue] =  useState({email:'', lastPassword:'', newPassword:'',retypePassword:''});
    const navigate = useNavigate();
    const [alert, setAlert] = useState([]);
    function input(e){
        setValue({...value, [e.target.name]:e.target.value});
    }


    const { id } = useAuth();


    function send(){

        if(value.newPassword !== ""){
            if(value.retypePassword === value.newPassword){
            
                fetch(`${url}/customer/CP/${id}`,{
                    method:"POST",
                    body:  JSON.stringify(value),
                    credentials: 'include'
                }).then((data)=>data.json()).then((data)=>{

                    if(data.status === true){

                        setAlert((alert)=>[...alert, <Alert1 key={ Date.now()} title="Successful" message={data.message} />]);
                        setTimeout(()=>{
                            navigate('/dashboard')
                        },2000);
                    }else{
                        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
                    }

                });
            }else{
                setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message="Retyped password not matched!" />]);
            }
        }else{
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message="Password will be in 6-20 characters!" />]);
        }

      }

      useEffect(()=>{
        window.scrollTo(0,0)
    
      },[]);
    

    return(
        <div className=' p-4  mb-20'>
            <div className= " absolute top-16 right-2 ">
                <AlertContainer>
                    {alert}
                </AlertContainer>
            </div>
            <form className='  md:w-[600px] h-auto mx-auto p-4 pb-10 flex flex-col rounded-md shadow-md bg-slate-300 relative ' >
                <h1 className=' text-center text-xl'> Change Info</h1>

                <label htmlFor='email' >Email:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="email" name="email" id="email" value={value.email} placeholder='Enter your email' />

                <label htmlFor='lastPassword' >Last Password:</label>
                <input onChange={(e)=>input(e)} className=" pl-2 mb-2  rounded-sm  border-none outline-none text-slate-500" type="password" name="lastPassword" id="lastPassword" value={value.lastPassword} placeholder='Enter your last password' />

                <label htmlFor='newPassword' >New Password:</label>
                <input onChange={(e)=>input(e)} className=" pl-2 mb-2  rounded-sm  border-none outline-none text-slate-500" type="password" name="newPassword" id="newPassword" value={value.newPassword} placeholder='Enter your new password' />

                <label htmlFor='retypePassword' >Retype Password:</label>
                <input onChange={(e)=>input(e)} className=" pl-2 mb-2  rounded-sm  border-none outline-none text-slate-500" type="password" name="retypePassword" id="retypePassword" value={value.retypePassword} placeholder='Retype your password' />

                <button onClick={send} className=' bg-orange-600 w-20 text-white mt-4 rounded-sm' type='button'>Save</button>

            </form>
        </div>
    )
}

export default ChangePassword;