import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import DSLayout from './components/DSLayout';
import useFirstLoad from './hooks/firstLoad';
import AllServices from './pages/AllServices';
import Home from './pages/Home';
import Login from './pages/Login';

import Package from './pages/Package';
import PackageDetails from './pages/PackageDetails';


import SignUp from './pages/SignUp';
import ViewService from './pages/ViewService';
import IsLogIn from './pages/IsLogIn';
import Dashboard from './pages/Dashboard';
import ChangeInfo from './pages/ChangeInfo';
import Cart from './pages/Cart';
import CheckOut from './pages/CheckOut';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentFaild from './pages/PaymentFaild';
import PaymentCancel from './pages/PaymentCancel';
import Career from './pages/Career';
import JobDetails from './pages/JobDetails';
import JobRequest from './pages/JobRequest';

import Sale from './pages/Sale';
import Reword from './pages/Reword';
import Invoice from './pages/Invoice';
import ChangePassword from './pages/ChangePassword';
import RequestService from './pages/RequestService';
//  import Service from './pages/Service';
import ViewVendor from './pages/ViewVendor';

import TermsAndConditions from './pages/TermsAndConditions';
import About from './pages/About';


//-------------------blog----------------- 

import BlogHome from './pages/blog/BlogHome';
import BlogCategory from './pages/blog/BlogCategory';
import Post from './pages/blog/Post';
      
//-------------------blog----------------- 

function App() {
  useFirstLoad();
  return (
    <Routes>
        <Route path='/' element={<Home />} />
        {/* <Route path='/s/:id' element={<Service />} /> */}
        <Route path='/allServices' element={<AllServices />} />
        <Route path='/allServices/:id' element={<AllServices />} />
        <Route path='service/:id' element={<ViewService />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='/about' element={<About />} />

{/* -------------------blog----------------- */}
        <Route path='/blog' element={<BlogHome />} />
        <Route path='/blog/Category/:id' element={<BlogCategory />} />
        <Route path='/blog/post/:id' element={<Post />} />

{/* -------------------blog----------------- */}

        <Route path='/' element={<Layout />} >
          <Route path='login' element={<Login />} />
          <Route path='Signup' element={<SignUp />} />

          <Route path='/package/:id' element={<Package />} />
          <Route path='/package/details/:id' element={<PackageDetails />} />

          <Route path='requestService' element={<RequestService />} />

          <Route path='career' element={<Career />} />
          <Route path='career/details/:id' element={<JobDetails />} />
          <Route path='career/:name' element={<JobRequest />} />
          
        </Route>

        <Route path='/cart' element={<Cart />} />
        <Route path='/checkout' element={<CheckOut />} />
        <Route path='/invoice/:id' element={<Invoice />} />


        <Route path='/' element={<IsLogIn />} >
          <Route path='/' element={<DSLayout />}>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/purchase/:id/vendor' element={<ViewVendor />} />
            <Route path='/changeInfo' element={<ChangeInfo />} />
            <Route path='/changePassword' element={<ChangePassword />} />
            <Route path='/purchase/:id' element={<Sale />} />
            <Route path='/reword' element={<Reword />} />
            <Route path='/paymentSuccess' element={<PaymentSuccess/>} />
            <Route path='/paymentFaild' element={<PaymentFaild/>} />
            <Route path='/paymentCancel' element={<PaymentCancel/>} />
          </Route>
        </Route>
    </Routes>
  );
}

export default App;
