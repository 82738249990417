import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import url from '../url';
import { Alert1, Alert2, AlertContainer } from '../components/Alert';

function JobRequest(){

    const [value, setValue] =  useState({name:'', email:'', phone:'', hire:'', want:'', hear:'', yourself:'', file:{}});
    const [alert, setAlert] = useState([]);
    const navigate = useNavigate();
    const { name : field } = useParams();


    function input(e){
        setValue({...value, [e.target.name]:e.target.value});
    }


    function send(){
        const formData = new FormData();

        if(value.file.size === undefined || value.name === "" || value.email === "" || value.phone === "" || value.yourself === "" || value.hear === "" || value.want === "" || value.hire === "" ){
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message="All fields are required." />]);
        }else{

            formData.append("name",value.name);
            formData.append("email",value.email);
            formData.append("phone",value.phone);
            formData.append("yourself",value.yourself);
            formData.append("field",field);
            formData.append("hear",value.hear);
            formData.append("want",value.want);
            formData.append("hire",value.hire);
            formData.append("resume", value.file);
            

            fetch(`${url}/vacancy/job`,{
                method:"POST",
                body:  formData,
                credentials: 'include'
            }).then((data)=>data.json()).then((data)=>{
                if(data.status === true){
                    setTimeout(()=>{
                        navigate(-1);
                    },8000);
                    setAlert((alert)=>[...alert, <Alert1 key={ Date.now()} title="Successful!" message={data.message} />]);
                }else{
                    setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Failed!" message={data.message} />]);
                }
            });
        }

    }
    function setFile(e){
        setValue({...value, file:e.target.files[0]});
    }

    useEffect(()=>{
        window.scrollTo(0,0)
    
    },[]);
      
    

    return(
        <>
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <div className=' p-4 -mt-10 mb-20'>
            <form className='  md:w-[600px] h-auto mx-auto p-4 pb-10 flex flex-col rounded-md shadow-md bg-slate-300 relative ' >
                <h1 className=' text-center text-xl'> Apply For Job</h1>
                <label htmlFor='name' >Name:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="text" name="name" id="name" value={value.name} placeholder='Enter your name' /> 
                <label htmlFor='email' >Email:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="email" name="email" id="email" value={value.email} placeholder='Enter your email' /> 
                <label htmlFor='phone' >Phone number:</label>
                <input onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="text" name="phone" id="phone" value={value.phone} placeholder='Enter your phone number' />

                <label htmlFor='hear' >How did you hear about this position?</label>
                <textarea onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="description" name="hear" id="hear" value={value.hear} placeholder='How did you hear about this position?' rows={3} />

                <label htmlFor='want' >Why do you want this job?</label>
                <textarea onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="description" name="want" id="want" value={value.want} placeholder='Why do you want this job? ' rows={3} />

                <label htmlFor='hire' >Why should we hire you?</label>
                <textarea onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="description" name="hire" id="hire" value={value.hire} placeholder='Why should we hire you?' rows={3} /> 

                <label htmlFor='yourself' >About Yourself:</label>
                <textarea onChange={(e)=>input(e)} className=" pl-2  rounded-sm  border-none outline-none text-slate-500" type="description" name="yourself" id="yourself" value={value.yourself} placeholder='Say something about yourself...' rows={3} /> 

                <label htmlFor='name' >Upload Resume: <span className=" text-xs text-red-600">( .docx or .pdf )</span></label>
                <input onChange={(e)=>setFile(e)} className=" pl-2 my-2  rounded-sm  border-none outline-none text-slate-500" type="file" name="resume" id="resume" placeholder='Upload your resume' /> 
                <button onClick={send} className=' bg-orange-600 w-20 text-white mt-4 rounded-sm' type='button'>Send</button>
            </form>
        </div>
        </>
    )
}

export default JobRequest;