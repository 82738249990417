const initialState = {_id:"", name:"", email:"", phone:"", address:"", img:""} ;

function customer(state = initialState, action){
    switch(action.type){
        case 'ADD_CUSTOMER' : 
            state = action.data;
            return state;

        default : return state;
    }

}

export default customer;