import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ZoomImage from '../components/ZoomImage';
import url from '../url';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TO_CART, ADD_QUANTITY, REMOVE_QUANTITY } from '../store/actions/cart';



function PackageDetails(){

    const [packag, setPackage] = useState({unit:{}, photos:[]});
    const [img, setImg] = useState("")
    const { id } = useParams();
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();

    useEffect(()=>{
        fetch(`${url}/package/${id}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{

            if(data.status === true){
                setPackage(data.data);
                if(data.data.photos[0] !== undefined){
                    setImg(data.data.photos[0].img)
                }
            }
        });
    },[id]);

    useEffect(()=>{
        window.scrollTo(0,0)
    
    },[]);



    const d = cart.filter((cart)=>{
        return(cart.id === id)
    });

    return(

      <div className=' w-full h-full p-4 pb-[120px] -mt-20 md:flex justify-center gap-4 '> 
        <div className=" w-full h-[300px] md:w-[400px] md:h-[400px]  xl:w-[500px] xl:h-[500px] ">
            {
            ( packag.photos[0] !== undefined)?
            <>
                <div className=" w-full h-auto border">
                    <ZoomImage src={img} />
                </div>
                
                <div className=" w-full h-[90px] mt-2 border flex">
                    {
                        packag.photos.map((data, index)=>{
                            return(
                                <button key={index} onClick={()=>setImg(data.img)} className=" w-[90px] h-[90px]  flex items-center justify-center ">
                                    {
                                        (img === data.img)?
                                        <img className=" w-[80px] h-[80px] border-2  border-orange-600 p-1" src={data.img} alt="" />:
                                        <img className=" w-[80px] h-[80px] border-2 p-1" src={data.img} alt="" />
                                    }
                                    
                                </button>
                            )
                        })
                    }
                </div>
            </>

            :<></>
            }
        </div>
        <div className=" w-[300px] md:w-[500px] xl:w-[700px] h-auto mt-40 md:mt-0">
            <div>
                <h1 className=" lg:text-2xl font-bold">{packag.name}</h1>
                <h3>
                    <span className=' font-bold'>Price : </span> 
                    {
                        (packag.discountPrice > 0)? <span className=' text-red-600 line-through'>{packag.price}  ৳</span> :<></>
                    }
                    {
                        (packag.discountPrice > 0)? <span> {packag.discountPrice}  ৳</span> :<span>{packag.price}  ৳</span>
                    }
                </h3>
                {
                    (packag.unit === undefined)?<></>:<h3><span className=' font-bold'>Quantity: </span><span>{packag.quantity}</span> <span>{packag.unit.shortName}</span></h3>
                }
                </div>
                <div className=' flex gap-2 pt-4'>
                    {
                        (d[0])?
                        <div className=' w-28 border-2 border-orange-600 rounded-md flex justify-between'>
                            <button onClick={()=> dispatch(REMOVE_QUANTITY({id:packag._id}))} className=' bg-orange-600 text-white px-[5px]'><i className="fa-solid fa-minus"></i></button>
                            <span className=' text-orange-600'>{d[0].quantity}</span>
                            <button onClick={()=> dispatch(ADD_QUANTITY({id:packag._id}))} className=' bg-orange-600 text-white px-[5px]'><i className="fa-solid fa-plus"></i></button>
                        </div>
                        :
                            (packag.discountPrice > 0)?
                            <button onClick={()=>dispatch(ADD_TO_CART({"id":packag._id, "name":packag.name, "pPrice":packag.purchasePrice, "price":packag.price, "discountPrice":packag.discountPrice,"unit":packag.unit.shortName,"pdQuantity":packag.quantity, "quantity":1 }))} className=' px-2 py-[2px] bg-orange-600 text-white rounded-md'>Add to cart</button>
                            :
                            <button onClick={()=>dispatch(ADD_TO_CART({"id":packag._id, "name":packag.name, "pPrice":packag.purchasePrice, "price":packag.price, "discountPrice":packag.price,"unit":packag.unit.shortName,"pdQuantity":packag.quantity, "quantity":1 }))} className=' px-2 py-[2px] bg-orange-600 text-white rounded-md'>Add to cart</button>
                    }                        
                </div>
                <div className=' text-justify mt-10'><span className=' font-bold'> </span> <div dangerouslySetInnerHTML={{ __html: packag.description }}></div></div>
            </div>
        </div>
    )

}

export default PackageDetails;