import React,{ useEffect } from 'react'
import Footer from '../components/Footer';
import NavHome from '../components/NavHome';
import ServiceGroupSlider from '../components/ServiceGroupSlider';
import ServiceSlider from '../components/ServiceSlider';
import quality from '..//assets/images/quality.svg';
import price from '..//assets/images/price.svg';
import equppied from '..//assets/images/equppied.svg';
import experts from '..//assets/images/experts.svg';
import clientList from '..//assets/images/client-list.jpg';
import downloadApp from '..//assets/images/download app.jpg';
import play from '..//assets/images/play.png';
import callCentrer from '..//assets/images/callCentrer.png';
import Rbanner from '..//assets/images/web-banner.png';
import Rbanner2 from '..//assets/images/web-banner2.jpg';
import HowVideo from '../components/HowVideo';
import Cart from '../components/Cart';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Nav from '../components/Nav';


function Home() {


  const forHome = useSelector((state)=>state.forHome);
  const tranding = useSelector((state)=>state.tranding);
  const RecentlyViews = useSelector((state)=>state.RecentlyViews);
  const serviceGroup = useSelector((state)=>state.category);

  
  useEffect(()=>{
    window.scrollTo(0,0)

  },[]);

  return (
    <>
    <div className=" hidden md:block">
      <NavHome />
    </div>
    <div className=" block md:hidden">
        <Nav />
    </div>
      <div className=' hidden md:block md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px] mx-auto -mt-20'>
        <ServiceGroupSlider />
      </div>

      <div className=" block md:hidden mt-20 box-border">
        {
          (serviceGroup.length > 0)?
          <table className=" w-full">
            <thead></thead>
            <tbody className=" w-full flex flex-col justify-center p-1">
              <tr className=" w-full">
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto flex flex-col items-center justify-center" to={"allServices/"+serviceGroup[5].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[5].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs">{serviceGroup[5].name}</h1>
                  </NavLink>
                </td>
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto flex flex-col items-center justify-center" to={"allServices/"+serviceGroup[7].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[7].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs">{serviceGroup[7].name}</h1>
                  </NavLink>
                </td>
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto text-center flex flex-col items-center justify-center" to={"allServices/"+serviceGroup[6].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[6].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs ">{serviceGroup[6].name}</h1>
                  </NavLink>
                </td>
                <td className="w-[180px] h-max border md:p-1 ">
                <NavLink className=" w-16 h-auto mx-auto text-center flex flex-col items-center justify-center" to={"allServices/"+serviceGroup[0].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[0].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs ">{serviceGroup[0].name}</h1>
                  </NavLink>
                </td>
              </tr>
              <tr className=" w-full">
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto flex flex-col items-center justify-center" to={"allServices/"+serviceGroup[4].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[4].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs">{serviceGroup[4].name}</h1>
                  </NavLink>
                </td>
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto flex flex-col items-center justify-center" to={"allServices/"+serviceGroup[3].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[3].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs">{serviceGroup[3].name}</h1>
                  </NavLink>
                </td>
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto text-center flex flex-col items-center justify-center" to={"allServices/"+serviceGroup[8].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[8].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs ">{serviceGroup[8].name}</h1>
                  </NavLink>
                </td>
                <td className="w-[180px] h-max border md:p-1 ">
                <NavLink className=" w-16 h-auto mx-auto text-center flex flex-col items-center justify-center" to={"allServices/"+serviceGroup[2].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[2].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs ">{serviceGroup[2].name}</h1>
                  </NavLink>
                </td>
              </tr>
              <tr className=" w-full">
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto flex flex-col items-center justify-center" to={"allServices/"+serviceGroup[1].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[1].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs">{serviceGroup[1].name}</h1>
                  </NavLink>
                </td>
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto flex flex-col items-center justify-center" to={"allServices/"+serviceGroup[9].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[9].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs">{serviceGroup[9].name}</h1>
                  </NavLink>
                </td>
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto text-center flex flex-col items-center justify-center" to={"allServices/"+serviceGroup[10].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[10].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs ">{serviceGroup[10].name}</h1>
                  </NavLink>
                </td>
                <td className="w-[180px] h-max border md:p-1 ">
                <NavLink className=" w-16 h-auto mx-auto text-center flex flex-col items-center justify-center" to={"allServices/"+serviceGroup[11].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[11].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs ">{serviceGroup[11].name}</h1>
                  </NavLink>
                </td>
              </tr>
            </tbody>
          </table>:
          <></>
        }
      </div>

      <div className=' w-full sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px] flex flex-col md:flex-row gap-4  mx-auto mt-5 xl:mt-16 2xl:mt-20'>
        <img className=' md:-ml-1 w-full md:w-1/2' src={Rbanner2} alt="" />
        <img className=' w-full md:w-1/2' src={Rbanner} alt="" />
      </div>

      <div className=' w-full px-1 sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px]  mx-auto mt-5 xl:mt-16 2xl:mt-20'>
        <h1 className=' text-lg lg:text-2xl py-2 font-bold'>For Your Home</h1>
        <ServiceSlider data={forHome} />
      </div>

      {
        (RecentlyViews.length > 3)?     
          <div className='  w-full px-1 sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px]  mx-auto mt-5 xl:mt-16'>
            <h1 className=' text-lg lg:text-2xl font-bold'>Recently Views</h1>
            <ServiceSlider data={RecentlyViews} />
          </div>:<></>
      }



      <div className='  w-full px-1 sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px]  mx-auto mt-5 xl:mt-16 '>
        <h1 className='text-lg lg:text-2xl font-bold'>Trending</h1>
        <ServiceSlider data={tranding} />
      </div>

      <div className=' w-full sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px] mx-auto  mt-16'>
        <div className=' flex items-center'>
          <div className=' hidden sm:block w-10 h-[2px] bg-black'></div>
          <h1 className=' pl-2 text-lg xl:text-xl uppercase font-bold text-slate-600'>Why you Choose <span className='text-orange-600'>Easy</span>Sheba</h1>
        </div>
        <h1 className=' text-lg xl:text-3xl font-bold pl-12 text-slate-600'>Because we care about your comfort<span className='text-orange-600'>...</span></h1>
        <div className=' h-max flex flex-wrap lg:flex-nowrap justify-center gap-8 mt-10'>
          <div className=' w-40 md:w-80 h-auto bg-slate-100 p-4 rounded-md hover:shadow-xl'>
            <img src={quality} alt="" />
            <h1 className=' font-bold text-base md:text-lg xl:text-2xl text-center'>100% Quality Assured</h1>
            <p className=' text-center text-xs'>If you don't love our service, we will make it right.</p>
          </div>
          <div className=' w-40 md:w-80 h-auto bg-slate-100 p-4 rounded-md hover:shadow-xl'>
            <img src={price} alt="" />
            <h1 className=' font-bold text-base md:text-lg xl:text-2xl text-center'>Affordable pricing</h1>
            <p className=' text-center text-xs'>See fixed prices before you book. No hidden charges.</p>
          </div>
          <div className=' w-40 md:w-80 h-auto bg-slate-100 p-4 rounded-md hover:shadow-xl'>
            <img src={experts} alt="" />
            <h1 className=' font-bold text-base md:text-lg xl:text-2xl text-center'>Experts only</h1>
            <p className=' text-center text-xs'>Our professionals are well trained and have on-job expertise.</p>
          </div>
          <div className=' w-40 md:w-80 h-auto bg-slate-100 p-4 rounded-md hover:shadow-xl'>
            <img src={equppied} alt="" />
            <h1 className=' font-bold text-base md:text-lg xl:text-2xl text-center'>Fully equipped</h1>
            <p className=' text-center text-xs'>We bring everything needed to get the job done well.</p>
          </div>
        </div>
        <div className=' mt-10 flex flex-wrap lg:flex-nowrap justify-center gap-8'>
          <div  className=' w-80 p-4 rounded-md bg-orange-600 text-white'>
            <h1 className=' text-2xl xl:text-4xl font-bold text-center'>100+</h1>
            <h1 className=' text-lg xl:text-2xl font-bold text-center'>Services</h1>
          </div>
          <div  className=' w-80 p-4 rounded-md bg-orange-600 text-white'>
            <h1 className='text-2xl xl:text-4xl font-bold text-center'>1000+</h1>
            <h1 className='text-lg xl:text-2xl font-bold text-center'>Service Men</h1>
          </div>          
          <div  className=' w-80 p-4 rounded-md bg-orange-600 text-white'>
            <h1 className='text-2xl xl:text-4xl font-bold text-center'>200000+</h1>
            <h1 className='text-lg xl:text-2xl font-bold text-center'>Order Served</h1>
          </div>
          <div  className=' w-80 p-4 rounded-md bg-orange-600 text-white'>
            <h1 className='text-2xl xl:text-4xl font-bold text-center'>100000+</h1>
            <h1 className='text-lg xl:text-2xl font-bold text-center'>Five Star Ratings</h1>
          </div>
        </div>
        <div className='pl-1 mt-20'>
          <h1 className=' text-2xl xl:text-3xl font-bold'> <span className='text-orange-600'>Our</span> Happy  Clients</h1>
          <img className=' w-full mt-5' src={clientList} alt="" />
          <h1 className=' float-right'>Trusted by 500+ customers for solving their problems...</h1>
        </div>
        <div className='pl-1 mt-20'>
          <h1 className=' text-lg lg:text-2xl xl:text-3xl font-bold text-slate-700 pb-10 '>Easiest way to get a service<span className='text-orange-600'>...</span></h1>
          <div className=' flex flex-col lg:flex-row gap-8'>
            <div className=' md:w-[700px] lg:w-[850px] h-[400px]'>
              <HowVideo />
            </div>
            <div className=' -mt-52 md:-mt-10 flex flex-col gap-10'>
              <div className=' flex items-center'>
                <div className=' w-4 md:w-12 h-8 md:h-12  px-4 flex items-center justify-center text-sm md:text-3xl shadow-md  rounded-full text-white bg-orange-600'><span>1</span></div>
                <div className=' flex flex-col pl-4'>
                  <h1 className=' text-sm md:text-xl font-bold'>Choose Your Package</h1>
                  <p className=' text-xs md:text-lg'>Choose and take any package you are searching for from the website or the app.</p>
                </div>
              </div>
              <div className=' flex items-center'>
                <div className='w-4 md:w-12 h-8 md:h-12 px-4 flex items-center justify-center text-sm md:text-3xl shadow-md  rounded-full text-white bg-orange-600'><span>2</span></div>
                <div className=' flex flex-col pl-4'>
                  <h1 className=' text-sm md:text-xl font-bold'>Confirm It</h1>
                  <p className=' text-xs md:text-lg'>Let us know your date and time to avail of the service. Please select the service provider based on their rating.</p>
                </div>
              </div>
              <div className=' flex items-center'>
                <div className='w-4 md:w-12 h-8 md:h-12 px-4 flex items-center justify-center text-sm md:text-3xl shadow-md  rounded-full text-white bg-orange-600'><span>3</span></div>
                <div className=' flex flex-col pl-4'>
                  <h1 className=' text-sm md:text-xl font-bold'>Sit Back and Relax</h1>
                  <p className=' text-xs md:text-lg'>It's time to relax. Easy Sheba, Easy Life.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='px-1 mt-20  md:mt-0'>
          <div className=' flex flex-col lg:flex-row gap-8'>
              <img src={downloadApp} className=' lg:w-[500px] xl:w-[650px] rounded-md' alt="" />
            <div className=' flex flex-col gap-1'>
              <h1 className=' font-bold text-slate-600 lg:text-lg xl:text-2xl'>DOWNLOAD OUR APP NOW<span className='text-orange-600'>...</span></h1>
              <h2 className=' font-bold text-slate-600 lg:text-sm xl:text-base'>And Get Your Service Any Time, Anywhere.</h2>
              <p className=' text-sm xl:text-base'>Give us your mobile number. We will send you a SMS with our app download link.</p>
              <div className=' w-72 border-2 flex border-orange-600 mt-5 rounded-sm'>
                <input className=' w-full px-2 h-8 border-none outline-none' type="text" placeholder='Type your mobile number...'/>
                <button className=' bg-orange-600 h-full pb-2 pt-1 px-2 text-white'><i className="fa-brands fa-android"></i></button>
              </div>
              <a className=' w-40 mt-10' href="https://play.google.com/store/apps/details?id=easysheba.com.customersapp" target="_blank" rel="noreferrer"><img src={play} alt="" /></a>
              {/* <a href="https://play.google.com/store/apps/details?id=multiplexer.lab.easysheba" target="_blank" rel="noopener noreferrer"><img src={play} alt="" /></a> */}
            </div>
          </div>
        </div>
      </div>
      <div className=' w-full h-96 pl-4 sm:pl-0 md:h-72 pt-5 md:pt-0 mt-16  md:mt-40 xl:mt-0 bg-slate-100'>
        <div className=' sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px] mt-10 mx-auto flex flex-col md:flex-row items-start md:items-center justify-between'>
          <div className=' flex flex-col gap-10'>
            <h1 className=' text-sm md:text-xl lg:text-2xl font-bold text-slate-600'>Want to Request For a Customize Service?</h1>
            <div >

                <NavLink to='/requestService' className=' px-2 py-2 rounded-md j text-xs md:text-sm text-orange-600 font-bold hover:bg-orange-600 hover:text-white border-2 border-orange-600'>REQUEST A SERVICE</NavLink>

              <a href="tel:09696-010506" className='ml-8 px-2 py-2 rounded-md text-xs md:text-sm hover:text-orange-600 font-bold bg-orange-600 text-white hover:bg-white border-2 border-orange-600'><i className="fa-solid fa-phone"></i> 09696-010506</a>
            </div>
          </div>
          <div>
            <img className=" w-72 md:w-96 hidden sm:block ml-60 md:ml-0 -mt-12 md:-mt-32" src={callCentrer} alt="" />
          </div>
        </div>
      </div>
      <Cart />
      <Footer />

    </>
  )
}

export default Home;