const initialState = [] ;

function tranding(state = initialState, action){
    switch(action.type){
        
        case 'ADD_ALL_TRANDING' : 
            state = action.data;
            return state;
           

        default : return state;
    }

}

export default tranding;