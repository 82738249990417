const initialState = [] ;

function customer(state = initialState, action){
    switch(action.type){
        case 'ADD_TO_CART' :

            const d = state.filter((data)=>{
                return( data.id === action.data.id);
            });

            if(d.length > 0){
                
            }else{
                state = [...state, action.data];
            }

            return state;
        
        
        case 'ADD_QUANTITY' :
            // const [de] = state.filter((data)=>{
            //     return( data.id === action.data.id);
            // });

            // const dt = {...de, "quantity":de.quantity+1}

            // const dc = state.filter((data)=>{
            //     return( data.id !== action.data.id);
            // });

            // state = [dt, ...dc];

            const s = state.map((data,index)=>{
                if(data.id === action.data.id){
                    data.quantity =  data.quantity + 1;
                }

                return(data);

            })

            // console.log(s);
            state = s;

            return state;


        case 'REMOVE_QUANTITY' :
            // const [dee] = state.filter((data)=>{
            //     return( data.id === action.data.id);
            // });

            // const dtt = {...dee, "quantity":dee.quantity-1}

            // if(dtt.quantity === 0){
            //     const dcc = state.filter((data)=>{
            //         return( data.id !== action.data.id);
            //     });
    
            //     state = [...dcc];
            // }else{
            //     const dcc = state.filter((data)=>{
            //         return( data.id !== action.data.id);
            //     });
    
            //     state = [dtt, ...dcc];
            // }
            
            let rid ;

            const ss = state.map((data,index)=>{
                if(data.id === action.data.id){
                    if(data.quantity === 1){
                        rid = data.id;
                    }else{
                        data.quantity =  data.quantity - 1;
                    }
                }

                return(data);
            })

            const a = ss.filter((data,index)=>{
                return(data.id !== rid)
  
            })




            state = a;

            return state;

        case 'REMOVE_FROM_CART' :
            const dcc = state.filter((data)=>{
                return( data.id !== action.data.id);
            });

            state = [...dcc];

            return state;

        case 'REMOVE_CART' :

            state = [];

            return state;

        default : return state;
    }

}

export default customer;