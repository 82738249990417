const initialState = [] ;

function forHome(state = initialState, action){
    switch(action.type){
        
        case 'ADD_ALL_FOR_HOME' : 
            state = action.data;
            return state;
           

        default : return state;
    }

}

export default forHome;