import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';

function Chat(){
    return(
      //   <MessengerCustomerChat
      //   pageId="102826721297012"
      //   appId="513772703749676"
      // />
      <></>

    )
}

export default Chat;