import { combineReducers } from 'redux';
import category from './category';
import customer from './customer';
import cart from './cart';
import forHome from './forHome';
import tranding from './tranding';
import RecentlyViews from './RecentlyViews';
import ViewPacakage from './ViewPacakage';


const rootReducer = combineReducers({
    category,
    customer,
    cart,
    forHome,
    tranding,
    RecentlyViews,
    ViewPacakage,

});

export default rootReducer;